const Storage = {
    /**
     * @param key
     * @param value
     */
    set(key, value) {
        window.localStorage.setItem(key, JSON.stringify(value));
    },

    /**
     * @param key
     * @returns {string}
     */
    get(key, defaultValue = {}) {
        return (Object.prototype.hasOwnProperty.call(window.localStorage,key)) ? JSON.parse(window.localStorage.getItem(key)) : defaultValue;
    }
};

export default Storage;