<template>
  <div>
    <div class="absolute w-full h-full top-0 left-0">
      <!-- card -->
      <div class="z-20 relative my-10 mx-3 bg-white text-center">
        <img
          src="@/assets/mysolarbook_logo_web.png"
          class="py-3 w-52 mx-auto"
        >
        <div class="relative bg-gray-800">
          <div class="relative max-w-7xl mx-auto px-4 py-7 sm:px-6 lg:px-8 lg:py-16">
            <div class="mx-auto md:w-1/2">
              <h2 class="text-base font-semibold uppercase tracking-wider text-gray-300">
                Takes no more than 3 minutes:
              </h2>
              <p class="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
                Thought about Solar, but haven't made the switch?
              </p>
              <p class="mt-3 text-md text-gray-300">
                In 2021 you can qualify for $0 down and increase your property value by at least $15K
              </p>
              <p class="my-2 text-md text-gray-100">
                MySolarBook.com connects thousands of homeowners to DIRECT providers for the BEST SOLAR INSTALLATION rates in the nation.
              </p>
              <div class="mt-4">
                <div class="block rounded-md shadow">
                  <a
                    href="#"
                    class="mb-2 block items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                    @click="submit"
                  >
                    Explore Options In {{ user_city }}
                  </a>
                  <a
                    href="#"
                    class="block items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-300 bg-gray-500 hover:bg-gray-50"
                    @click="quote"
                  >
                    No, I don't like saving money
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- bg -->
      <div class="z-10 absolute w-full h-full top-0 left-0 bg-black opacity-50" />
    </div>
  </div>
</template>
<script>
    import Storage from '@/storage.js';
    export default {
        data: () => ({
            user_city: '',
        }),
        mounted() {
            // todo: fire google analytics tracking
            this.user_city = Storage.get('user.city')
        },
        methods: {
            submit() {
                // need to collect:
                let user = {
                    first_name: Storage.get('user.first_name'),
                    last_name:  Storage.get('user.last_name'),
                    email:      Storage.get('user.email_address'),
                    phone:      Storage.get('user.phone_number').replace('(','').replace(')','').replace('-','').replace(' ',''),
                    address:    Storage.get('user.address'),
                    zip:        Storage.get('user.zip_code'),
                    dob_month:  Storage.get('user.dob').dob_month,
                    dob_day:    Storage.get('user.dob').dob_day,
                    dob_year:   Storage.get('user.dob').dob_year,
                    token:      Storage.get('user.token')
                };
                window.location = `https://www.mysolarbook.com?utm_medium=${user.token}&utm_source=ehi_redirect&utm_content=after_reg_popup&utm_channel=internal&utm_keyword=take_action_now`;
            },
            quote() {
                if(window.__screen === 'giveaway') {
                    this.$router.push('/confirmation');
                } else if(window.__screen === 'calculator') {
                    this.$router.push('/deal-calculator');
                } else {
                    this.$router.push('/survey-information');
                }
            }
        }
    };
</script>