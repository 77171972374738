<template>
  <div id="scripts">
    Loading...
  </div>
</template>

<script>
import Storage from '@/storage'

export default {
  computed: {
    member () {
      return Storage.get('member')
    },
  },
  mounted () {
    document.getElementById('scripts').innerHTML = ""

    const PDregData = {};
    PDregData.SubMid = 'EssentialHealthInfo';
    PDregData.exit_url = window.location.origin + '/offers';
    PDregData.first_name = this.member.first_name;
    PDregData.last_name = this.member.last_name;
    PDregData.email = this.member.email_address;
    PDregData.phone = this.member.phone_number;
    PDregData.address1 = this.member.address;
    PDregData.city = this.member.city;
    PDregData.state = this.member.state;
    PDregData.zipcode = this.member.zip_code;
    PDregData.gender = this.member.gender[0].toUpperCase();
    PDregData.dob_month = this.member.dob_month;
    PDregData.dob_day = this.member.dob_day;
    PDregData.dob_year = this.member.dob_year;

    window.PDregData = PDregData;

    this.attachScripts();
  },
  methods: {
    attachScripts () {
      const adQuireSetup = document.createElement('script')
      adQuireSetup.setAttribute('id', 'PD_Script_Configs')
      adQuireSetup.setAttribute('data-cfasync', 'false')
      adQuireSetup.setAttribute('src', 'https://www.pdapi.com/cs/JSI/v1/ACW/PDconfigs.js')
      document.getElementById('scripts').appendChild(adQuireSetup)

      const adQuireRecovery = document.createElement('script')
      adQuireRecovery.setAttribute('id', 'PD_Script_Recovery')
      adQuireRecovery.innerText = `if (typeof oAdQuireLoader != 'object' && typeof PDregData == 'object' && PDregData.exit_url){ window.location.href = PDregData.exit_url;}`
      document.getElementById('scripts').appendChild(adQuireRecovery)
    }
  }
}
</script>
