<template>
  <div class="">
    <div class="">
      <h1
        class="text-blue-400"
        style="margin-bottom: 120px"
      >
        .
      </h1>
    </div>
    <div
      v-show="elementVisible"
      class="animate__animated animate__backInLeft"
    >
      <!-- <div class="md:w-80 md:m-auto md:mt-1 px-2 py-3  bg-pink-200  overflow-hidden rounded-t-lg  ">
      <a @click="callSolarInbound" class="block text-center  bg-gradient-to-t from-red-400 to-pink-500 justify-center  rounded-md border-0  px-4 py-2  text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="inline-block" fill="white" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
          </svg>&nbsp;<span class="text-white font-medium ml-3 ">Tap to call now for a quick quote</span>
      </a>
    </div> -->
    </div>
    <div
      style="margin-top: 0px"
      class="
        md:w-96 md:m-auto
        mb-20
        px-2
        pt-4
        mt-10
        bg-white
        shadow-xl
        overflow-hidden
        rounded-lg
        shadow-lg
      "
    >
      <img
        src="../../assets/essential-health-logo.png"
        style="width: 270px"
        class="m-auto h-15 mt-0 mb-0"
      >
      <img
        src="https://ecasd.instructure.com/courses/11363/files/68809/preview?instfs=false"
        class="w-full h-5 mt-0 mb-0"
      >
      <div class="px-4 pb-4">
        <div>
          <p class="text-black-500 text-2xl md:font-sans text-center font-bold">
            Welcome!
          </p>
          <p
            class="
              mt-4
              text-gray-500 text-m
              md:font-sans
              text-center
              font-medium
            "
          >
            Click “Continue” and get started <br>
            to win!
          </p>

          <button
            class="
              mt-15 mt-5
              mb-5
              submit-button
              border-0
              rounded-m
              bg-gradient-to-t
              from-blue-400
              to-indigo-400
            "
            @click="nextStep"
          >
            <span
              class="text-lg"
            >Continue
              <img
                v-if="this.route === '/smart-tv'"
                class="inline-block ml-2 w-10 h-10"
                src="../../assets/vizio_micro.png"
              ></span>
          </button>
          <div
            class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200"
          >
            <div
              :style="{ width: '20' + '%' }"
              class="
                shadow-none
                flex flex-col
                text-center
                whitespace-nowrap
                text-white
                justify-center
                bg-blue-400
              "
            />
          </div>
        </div>
      </div>
    </div>

    <center>
      <p
        class="mt-5 text-white"
        style="font-size: 0.6rem"
      >
        <a
          href="http://app.essentialhealthinfo.com/privacy-policy"
          class="text-underline inline-block m-1"
        >Privacy Policy</a>
        <a
          href="http://app.essentialhealthinfo.com/termsandconditions"
          class="text-underline inline-block m-1"
        >Terms And Conditions</a>
        <a
          href="http://app.essentialhealthinfo.com/ccpa-do-not-sell-my-information"
          class="text-underline inline-block m-1"
        >Do Not Sell My Information</a><br>
        <a
          href="http://app.essentialhealthinfo.com/ccpa-your-california-privacy-rights"
          class="text-underline inline-block m-1"
        >California Residents</a>
      </p>
    </center>
  </div>
</template>

<script>
export default {
  name: "Gate",
  props: ["route"],
  data() {
    return {
      elementVisible: true,
    };
  },
  created() {
    setTimeout(() => (this.elementVisible = true), 8000);
  },
  methods: {
    nextStep() {
      this.$router.push(
        this.route !== undefined ? this.route : "/purple-pillow"
      );
    },
  },
};
</script>

<style scoped>
</style>
