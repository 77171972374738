import { render, staticRenderFns } from "./InstantPot.vue?vue&type=template&id=18c6335c&scoped=true&"
import script from "./InstantPot.vue?vue&type=script&lang=js&"
export * from "./InstantPot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c6335c",
  null
  
)

export default component.exports