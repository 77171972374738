<template>
  <div class="card">
    <div class="text-left">
      <p class="mb-4 text-xs font-bold text-center text-gray-500">
        Please confirm the below information:
      </p>

      <p class="font-semibold text-black-500">
        Have you used tobacco recently?
      </p>
      <p class="text-xs text-black-300">
        Within the last 12 months:
      </p>
      <div class="block">
        <button
          :class="'ml-0 ' + (tobacco ? 'reg-button-selected' : 'reg-button')"
          @click="tobacco = true"
        >
          Yes
        </button>
        <button
          :class="!tobacco ? 'reg-button-selected' : 'reg-button'"
          @click="tobacco = false"
        >
          No
        </button>
      </div>

      <p class="mt-4 font-semibold text-black-500">
        Do you make more than $12k?
      </p>
      <p class="text-xs text-black-300">
        To qualify you for our executive benefits program:
      </p>
      <div class="block">
        <button
          :class="'ml-0 ' + (overtwenty ? 'reg-button-selected' : 'reg-button')"
          @click="overtwenty = true"
        >
          Yes
        </button>
        <button
          :class="!overtwenty ? 'reg-button-selected' : 'reg-button'"
          @click="overtwenty = false"
        >
          No
        </button>
      </div>

      <button
        :disabled="!validationIsTrue"
        class="mt-5 submit-button"
        @click="nextStep"
      >
        <svg
          v-if="isLoading"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          class="inline-block animate-spin"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
          />
          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
        </svg>&nbsp;Confirm Questions
      </button>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/eventbus";
import Storage from "@/storage";

export default {
  name: "AdditionalQuestions",
  data() {
    return {
      isLoading: false,
      tobacco: false,
      overtwenty: true
    };
  },
  methods: {
    nextStep() {
      this.isLoading = true;

      Storage.set('app.third_step',{
        tobacco: this.tobacco,
        income: this.overtwenty
      });

      setTimeout(() => {
        EventBus.$emit('app.progressBar.update',80);
        this.$router.push('/insurance-info');
      },150);
    },
    validationIsTrue() {
      return true;
    }
  }
}
</script>