<template>
  <div class="card">
    <div class="text-left">
      <p class="text-sm font-semibold text-black-500">
        1 in 10 people have Type 2 Diabetes.
      </p>
      <p class="text-xs text-black-300">
        Have you, or a loved one been diagnosed with Diabetes?
      </p>
      <div class="block">
        <button
          :class="'ml-0 ' + (diabetes ? 'reg-button-selected' : 'reg-button')"
          @click="diabetes = true"
        >
          Yes
        </button>
        <button
          :class="!diabetes ? 'reg-button-selected' : 'reg-button'"
          @click="diabetes = false"
        >
          No
        </button>
      </div>

      <p class="mt-3 text-sm font-semibold text-black-500">
        48% of people in the US have or are at risk of cardiovascular disease.
      </p>
      <p class="text-xs text-black-300">
        You can prevent these easily. Do you want to know more?
      </p>
      <div class="block">
        <button
          :class="'ml-0 ' + (cpap ? 'reg-button-selected' : 'reg-button')"
          @click="cpap = true"
        >
          Yes
        </button>
        <button
          :class="!cpap ? 'reg-button-selected' : 'reg-button'"
          @click="cpap = false"
        >
          No
        </button>
      </div>

      <p class="mt-3 text-sm font-semibold text-black-500">
        Every year an additional 8 million people suffer from sleep problems.
      </p>
      <p class="text-xs text-black-300">
        Using a CPAP machine can help with this. Are you interested?
      </p>
      <div class="block">
        <button
          :class="'ml-0 ' + (cardio ? 'reg-button-selected' : 'reg-button')"
          @click="cardio = true"
        >
          Yes
        </button>
        <button
          :class="!cardio ? 'reg-button-selected' : 'reg-button'"
          @click="cardio = false"
        >
          No
        </button>
      </div>



      <p class="mt-3 mb-2 text-xs font-bold text-center text-black-500">
        Please help us with the above information to:
      </p>
      <div class="p-3 bg-gray-100">
        <ul class="text-xs text-center text-white-500">
          <li>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="inline-block"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
              />
            </svg>Save money on essential healthcare
          </li>
          <li>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="inline-block"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
              />
            </svg>Qualify for additional benefits
          </li>
          <li>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="inline-block"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
              />
            </svg>Keep your policy information updated
          </li>
        </ul>
      </div>

      <button
        :disabled="!validationIsTrue"
        class="inline-flex justify-center w-full px-4 py-2 mt-4 text-sm font-medium text-gray-700 bg-white border border-gray-700 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
        @click="nextStep"
      >
        <svg
          v-if="isLoading"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          class="inline-block animate-spin"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
          />
          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
        </svg>&nbsp;View Your 3 Quotes
      </button>
    </div>
  </div>
</template>

<script>
import Storage from "@/storage";
import {EventBus} from "@/eventbus";

export default {
  name: "InterestInOffers",
  data() {
    return {
      isLoading: false,
      diabetes: true,
      cpap: true,
      cardio: true
    };
  },
  methods: {
    nextStep() {
      this.isLoading = true;

      let dataToPush = {
        diabetes: this.tobacco,
        cpap: this.overtwenty,
        cardio: this.cardio
      };

      Storage.set('app.fifth_step',dataToPush);

      setTimeout(() => {
        EventBus.$emit('app.coregOffers',dataToPush);
        this.$router.push('/quotes');
      },150);
    },
    validationIsTrue() {
      return true;
    }
  }
}
</script>

<style scoped>

</style>