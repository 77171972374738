<template>
  <div
    class="
      md:w-96 md:m-auto md:mt-5
      m-2
      p-5
      bg-white
      shadow
      overflow-hidden
      rounded-lg
    "
  >
    <img
      src="../../assets/essential-health-logo.png"
      class="block w-32 m-auto mb-2"
    />
    <div class="card">
      <div class="text-left">
        <!--    <p class="text-black-500 font-semibold">Postal Code*</p>-->
        <!--    <input pattern="[0-9]*" type="text" v-model="postalCode" v-mask="'#####'" :class="postalCodeError ? 'reg-input border-red-500' : 'reg-input'" placeholder="92656"/>-->
        <!--    <p v-if="postalCodeError" class="mt-1 text-xs text-red-500">Invalid zip code!</p>-->
        <center>
          <p class="text-black-500 font-semibold">
            Please send me information about Dental Insurance
          </p>
        </center>
        <p class="mt-4 text-black-400 md:font-sans">First Name*</p>
        <input
          v-model="firstName"
          name="first_name"
          class="
            mt-2
            p-2.5
            border border-gray-300
            rounded-lg
            w-full
            block
            focus:outline-none
            focus:ring-2
            focus:ring-blue-500
            focus:border-transparent
            font-sans
          "
          placeholder="Mia"
        />

        <p class="mt-4 text-black-400 md:font-sans">Last Name*</p>
        <input
          v-model="lastName"
          name="last_name"
          class="
            mt-2
            p-2.5
            border border-gray-300
            rounded-lg
            w-full
            block
            focus:outline-none
            focus:ring-2
            focus:ring-blue-500
            focus:border-transparent
            font-sans
          "
          placeholder="Smith"
        />

        <div v-if="!fbEmailExists">
          <p class="text-black-500 mt-2">Email Address*</p>
          <input
            v-model="emailAddress"
            :class="
              emailAddressError ? 'reg-input border-red-500' : 'reg-input'
            "
            type="email"
            placeholder="john.doe@gmail.com"
          />
          <p v-if="emailAddressError" class="mt-1 text-sm text-red-500">
            Please enter a valid email.
          </p>
        </div>

        <p class="text-black-500 mt-2">Postal Code*</p>
        <input
          v-model="postalCode"
          v-mask="'#####'"
          pattern="[0-9]*"
          type="text"
          :class="postalCodeError ? 'reg-input border-red-500' : 'reg-input'"
          placeholder="92656"
        />
        <p v-if="postalCodeError" class="mt-1 text-xs text-red-500">
          Invalid zip code!
        </p>
      </div>
      <center>
        <div class="mt-5 text-sm mb-5">
          <p class="font-bold">For dental plans, we partner with:</p>
          <div class="block">
            <div class="inline-block">
              <ul>
                <li>
                  <svg
                    width="2em"
                    height="2em"
                    viewBox="0 0 16 16"
                    class="inline-block"
                    :fill="colorChangeOne"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    /></svg
                  >Guardian Direct
                </li>
              </ul>
            </div>
          </div>
        </div>
      </center>

      <label class="mt-1 text-gray-400" style="font-size: 0.65rem"
        ><input
          type="checkbox"
          class="mr-2 checked:bg-blue-600 checked:border-transparent"
          checked
        />
        By submitting this form, I expressly consent to be contacted by a
        <a
          href="https://www.acquirecrowd.com/partners/"
          target="blank"
          style="text-decoration: underline"
          >partner</a
        >
        in the AcquireCrowd Network with materials for services via direct or
        electronic mail, phone calls to the phone number provided, text/SMS
        messages, via automatic/automated dialing system(s), and pre-recorded
        messages. Consent is not a condition of purchase and may be revoked at
        any time.
      </label>

      <button
        :disabled="!validationIsTrue"
        class="mt-3 submit-button"
        @click="nextStep"
      >
        <svg
          v-if="isLoading"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          class="inline-block animate-spin"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
          />
          <path
            d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
          /></svg
        >&nbsp;Submit
      </button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventbus";
import Storage from "@/storage";
import axios from "axios";
export default {
  name: "PostCodeAndName",
  data() {
    return {
      isLoading: false,
      postalCode: "",
      postalCodeError: false,
      emailAddress: "",
      emailAddressError: false,
      firstName: "",
      lastName: "",
      colorChangeOne: "#000",
      colorChangeTwo: "#000",
      colorChangeThree: "#000",
    };
  },
  watch: {
    postalCode(oldVal, newVal) {
      if (newVal !== "") {
        this.colorChangeOne = "#3b82f6";
      }
    },
    firstName(oldVal, newVal) {
      if (newVal !== "") {
        this.colorChangeTwo = "#3b82f6";
      }
    },
    lastName(oldVal, newVal) {
      if (newVal !== "") {
        this.colorChangeThree = "#3b82f6";
      }
    },
  },
  mounted() {
    EventBus.$on("app.postalCode.loaded", (d) => {
      this.postalCode = d;
    });
    this.$nextTick(() => {
      Storage.set("channel", "high");
    });
  },
  methods: {
    loginWithFacebook() {
      window.FB.login(
        (res) => {
          console.log(res);
        },
        { scope: "public_profile,email,birthdate" }
      );
    },
    validationIsTrue() {
      return (
        this.postalCode !== "" && this.firstName !== "" && this.lastName !== ""
      );
    },
    nextStep() {
      this.isLoading = true;
      axios
        .get(
          "https://essentialhealth.app/api/lookup-zipcode?postalCode=" +
            this.postalCode
        )
        .then((d) => {
          if (
            !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              this.emailAddress
            )
          ) {
            this.isLoading = false;
            this.emailAddressError = true;
            console.log("email address error");
          } else {
            this.emailAddressError = false;
            if (
              Object.prototype.hasOwnProperty.call(d.data["ZipCode"], "Error")
            ) {
              this.postalCodeError = true;
              this.isLoading = false;
            } else {
              Storage.set("user.first_name", this.firstName);
              Storage.set("app.first_step", {
                postal_code: this.postalCode,
                city: d.data["ZipCode"]["City"],
                state: d.data["ZipCode"]["State"],
                first_name: this.firstName,
                last_name: this.lastName,
              });
              Storage.set("user.city", d.data["ZipCode"]["City"]);
              Storage.set("user.zip_code", this.postalCode);
              EventBus.$emit("app.progressBar.update", 40);
              this.$router.push("/survey-information");
            }
          }
        });
    },
  },
};
</script>

<style scoped>
</style>
