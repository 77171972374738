<template>
  <div class="card">
    <div class="text-left">
      <form @submit.prevent="nextStep">
        <p class="font-semibold text-black-500">
          Email Address <small>*</small>
        </p>
        <input
          v-model="emailAddress"
          :class="emailAddressError ? 'reg-input border-red-500' : 'reg-input'"
          type="email"
          placeholder="john.doe@gmail.com"
        >
        <p
          v-if="emailAddressError"
          class="mt-1 text-sm text-red-500"
        >
          Please enter a valid email.
        </p>

        <p class="mt-3 font-semibold text-black-500">
          Mobile Phone Number <small>*</small>
        </p>
        <input
          v-model="phoneNumber"
          v-mask="'(###) ###-####'"
          type="text"
          :class="phoneNumberError ? 'reg-input border-red-500' : 'reg-input'"
        >
        <p
          v-if="phoneNumberError"
          class="mt-1 text-sm text-red-500"
        >
          Please enter a valid mobile phone number.
        </p>

        <p class="mt-3 font-semibold text-black-500">
          Street Address <small>*</small>
        </p>
        <input
          v-model="address"
          :class="addressError ? 'reg-input border-red-500' : 'reg-input'"
          placeholder="1233 Main St"
        >
        <p
          v-if="addressError"
          class="mt-1 text-sm text-red-500"
        >
          Please enter a valid street address.
        </p>

        <button
          :disabled="isLoading || !formIsValid"
          class="inline-flex justify-center w-full px-4 py-2 mt-5 text-sm font-bold text-white bg-blue-500 border border-blue-500 rounded-md shadow-sm hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          type="submit"
        >
          <svg
            v-if="isLoading"
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="inline-block my-1 animate-spin"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
            />
            <path
              d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
            />
          </svg>

          <span v-else>Form Submit</span>
        </button>
      </form>

      <label
        class="mt-1 text-gray-400"
        style="font-size: 0.65rem"
      ><input
        id="leadid_tcpa_disclosure"
        type="hidden"
      > By submitting this
        form, I expressly consent to be contacted by a
        <a
          href="https://www.acquirecrowd.com/partners/"
          target="_blank"
          style="text-decoration: underline"
        >partner</a>
        in the AcquireCrowd Network with materials for services via direct or
        electronic mail, phone calls to the phone number provided, text/SMS
        messages, via automatic/automated dialing system(s), and pre-recorded
        messages. Consent is not a condition of purchase and may be revoked at
        any time.
      </label>
    </div>
  </div>
</template>

<script>
import Storage from "@/storage";
import Backend from "@/backend";
import { EventBus } from "@/eventbus";
import { mask } from "maska";

export default {
  name: "InformationOptIn",
  data() {
    return {
      offer_id_from_top: 0,
      submitted: false,
      fbEmailExists: false,
      isLoading: false,
      emailAddress: "",
      emailAddressError: false,
      phoneNumber: "",
      phoneNumberError: false,
      address: "",
      addressError: false,
      newScreen: "",
    };
  },
  computed: {
    unformattedPhone() {
      return mask(this.phoneNumber, "##########");
    },
    addressIsValid() {
      return this.address.length > 0;
    },
    emailIsValid() {
      return this.emailAddress.length > 0;
    },
    phoneNumberIsValid() {
      return this.unformattedPhone.length === 10;
    },
    formIsValid() {
      return (
        this.addressIsValid && this.emailIsValid && this.phoneNumberIsValid
      );
    },
  },
  mounted() {
    EventBus.$emit("app.stepChanged", 4);
    this.newScreen = window.newScreen;

    let fbEmail = Storage.get("user.fb.email");
    if (fbEmail !== undefined && fbEmail !== "" && fbEmail instanceof String) {
      this.fbEmailExists = true;
      this.emailAddress = fbEmail;
    }

    window.dotq = window.dotq || [];
    window.dotq.push({
      projectId: "10000",
      properties: {
        pixelId: "10156295",
        qstrings: {
          et: "custom",
          ec: "getquote",
        },
      },
    });

    this.offer_id_from_top = Storage.get("everflow.offer.id");
  },
  methods: {
    nextStep() {
      this.isLoading = true;
      this.phoneNumberError = false;

      Storage.set("app.fourth_step", {
        email_address: this.emailAddress,
        phone_number: this.unformattedPhone,
        address: this.address,
      });

      EventBus.$emit("app.progressBar.update", 100);

      Backend.validatePhone(this.unformattedPhone).then((d) => {
        if (d.data.valid) {
          Backend.registerUser().then((d) => {
            Storage.set("user.address", this.address);
            Storage.set("user.start_url", d.start_url);
            Storage.set(
              "user.entry_number",
              Math.floor(
                Math.random() * (Math.floor(100) - Math.ceil(500)) +
                  Math.ceil(500)
              )
            );

            window.fbq(
              "track",
              "Lead",
              {
                external_id: d.token,
                currency: "USD",
                value: 3.5,
              },
              { eventID: d.token }
            );

            if (window.gtag) {
              window.gtag("event", "generate_lead", {
                event_category: "funnel",
              });
            }

            let ipAddress = Storage.get("app.clientIpAddress");

            window.EF.conversion({
              order_id: ipAddress,
              offer_id: this.offer_id_from_top,
              email: this.emailAddress,
            });

           this.$router.push("/midpath")
          });
        } else {
          if (window.gtag) {
            window.gtag("event", "invalid_phone", {
              event_category: "funnel",
            });
          }

          this.isLoading = false;
          this.phoneNumberError = true;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
