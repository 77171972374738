<template>
  <div id="app">
    <template v-if="isPage">
      <div class="out-page">
        <router-view />
      </div>
    </template>

    <template v-else>
      <div>
        <div
          v-if="exitIntentModal"
          class="mt-6"
        >
          <!-- Dialog (full screen) -->
          <div
            class="
              absolute
              top-0
              left-0
              flex
              items-center
              justify-center
              w-full
              h-full
            "
            style="background-color: rgba(0, 0, 0, 0.5)"
            x-show="open"
          >
            <!-- dialog box -->
            <div
              v-if="exitIntentModal"
              class="
                md:w-96 md:m-auto md:mt-5
                m-2
                p-5
                bg-white
                shadow
                overflow-hidden
                rounded-lg
              "
            >
              <div class="p-5 text-center">
                <a
                  class="block text-gray-500 mb-5"
                  @click="exitIntentModal = false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="inline-block"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                  <span class="inline-block">Discover Savings</span>
                </a>
                <img
                  src="./assets/clickToCall.png"
                  class="w-56 block m-auto"
                >
                <p class="text-xl text-black-500 font-semibold">
                  Tired of answering questions? 😴
                </p>
                <p class="mt-5">
                  Speak with a live agent! Call now. 🎉
                </p>
                <a
                  href="tel:+18554180515"
                  class="
                    mt-5
                    block
                    text-center
                    justify-center
                    w-full
                    rounded-md
                    border border-blue-500
                    shadow-sm
                    px-4
                    py-2
                    bg-blue-500
                    text-sm
                    font-bold
                    text-white
                    hover:bg-gray-50
                    focus:outline-none
                  "
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="inline-block"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"
                    /></svg>+1-855-418-0515
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          class="
            md:w-96 md:m-auto md:mt-5
            m-2
            p-5
            bg-white
            shadow
            overflow-hidden
            rounded-lg
          "
        >
          <img
            v-if="showLogo"
            src="./assets/essential-health-logo.png"
            class="block h-16 m-auto mb-2"
          >

          <div v-if="screen === 'giveaway'">
            <div class="table text-center w-100 m-auto">
              <div class="table-cell p-2">
                <span class="text-black-500 font-bold text-2xl">{{
                  dynamicEntryCount
                }}</span><br>
                <span class="text-gray-400">Entries</span>
              </div>
              <div class="table-cell p-2">
                <div class="text-black-500 font-bold text-2xl">
                  30
                </div>
                <span class="text-gray-400">Days Left</span>
              </div>
            </div>
          </div>

          <router-view />

          <template v-if="isFacebookOffer">
            <div>
              <p
                v-if="screen === 'giveaway' && progressBar === 20"
                class="text-xs mt-5 text-gray-500"
              >
                This site is not a part of the Facebook™ website or Facebook™
                Inc. Additionally, this site is NOT endorsed by Facebook™ in any
                way. FACEBOOK™ is a trademark of FACEBOOK™, Inc.
              </p>
            </div>
          </template>
          <template v-if="isSnapchatOffer">
            <div>
              <p class="text-center text-gray-400 text-xs mt-5">
                "This site is not a part of the Snapchat™ website or Snapchat™
                Inc. Additionally, this site is NOT endorsed by Snapchat™ in any
                way. SNAPCHAT™ is a trademark of Snapchat™, Inc. This site is
                not a part of the (PowerXL) website or (PowerXL) Inc.
                Additionally, this site is NOT endorsed by (PowerXL), Inc. in
                any way.(PowerXL Air Fryer Grill ™) is a trademark of
                (PowerXL)."
              </p>
            </div>
          </template>

          <center>
            <p
              class="mt-5 text-gray-400"
              style="font-size: 0.6rem"
            >
              <a
                href="http://app.essentialhealthinfo.com/privacy-policy"
                class="text-underline inline-block m-1"
              >Privacy Policy</a>
              <a
                href="http://app.essentialhealthinfo.com/termsandconditions"
                class="text-underline inline-block m-1"
              >Terms And Conditions</a>
              <a
                href="http://app.essentialhealthinfo.com/ccpa-do-not-sell-my-information"
                class="text-underline inline-block m-1"
              >Do Not Sell My Information</a>
              <a
                href="http://app.essentialhealthinfo.com/ccpa-your-california-privacy-rights"
                class="text-underline inline-block m-1"
              >California Residents</a>
              <a
                href="http://app.essentialhealthinfo.com/sweepstake-rules"
                class="text-underline inline-block m-1"
              >Sweepstakes Rules</a>
            </p>
          </center>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { EventBus } from "@/eventbus";
import Storage from "@/storage";
import Backend from "@/backend";
import axios from "axios";

const pages = [
  "sweepstakes-rules",
  "dental-insurance",
  "termsandconditions",
  "privacy-policy",
  "ccpa-do-not-sell-my-information",
  "ccpa-your-california-privacy-rights",
  "gate",
  "master-gater",
];

const snapchatOffers = [
  "laptop-s",
  "standing-desk-s",
  "le-creuset-s",
  "grill-s",
  "manscaped-s",
  "dyson-s",
  "cube-s",
  "beats-s",
  "testkit-s",
  "instantpot-s",
  "subway-s",
  "roomba-s",
  "airfryer-s",
  "yeti-s",
  "range-s",
  "purple-pillow-s",
  "oura-ring-s",
  "smart-watch-s",
];

const facebookOffers = [
  "laptop",
  "standing-desk",
  "le-creuset",
  "grill",
  "manscaped",
  "dyson",
  "cube",
  "beats",
  "testkit",
  "instantpot",
  "subway",
  "roomba",
  "airfryer",
  "yeti",
  "range",
  "purple-pillow",
  "oura-ring",
  "smart-watch",
  "blackstone",
  "accessory",
  "egg",
  "versa",
  "smoker",
  "biolite",
  "bike",
  "karaoke",
  "waffle",
  "miko",
  "juicer",
  "lazy",
  "flair",
  "golf-set",
  "switch",
  "tent",
  "panga",
  "jbl",
  "fryer",
  "e-bike",
  "fit-bit",
  "kitchen-ware",
  "aeris-aair",
  "supe73-bike",
  "nest",
  "ipad",
  "volt",
  "apple-homepod",
  "home-depot-giftcard",
  "yeti-trailhead-two-in-one-dog-bed",
  "titan-telescoping-flagpole",
  "electric-kick-scooter",
  "5g-wifi-projector",
  "bluetooth-stereo-turntable",
  "yamaha-4-string-bass-guitar",
  "trifecta-meals",
  "freshly_meals",
  "lazyboy",
  "flair-espresso",
];

export default {
  data() {
    return {
      blockPage: false,
      showLogo: true,
      dynamicEntryCount: 238,
      dynamicDayCount: 3,
      channel: "Tier 1",
      currentStep: 1,
      screen: "signup",
      quoteNumber: "#00928",
      first_name: "Thomas",
      city: "ALISO VIEJO",
      state: "CA",
      progressBar: 20,
      exitIntentModal: false,
      timeLeft: 0,
      startTime: 0,
    };
  },
  computed: {
    route() {
      return window.location;
    },
    isPage() {
      return pages.reduce((carry, page) => {
        return carry || this.$route.path.includes(page)
      }, false)
    },
    isConfirmation() {
      return this.$route.name === 'confirmation'
    },
    isFacebookOffer() {
      return facebookOffers.includes(this.$route.path.replace("/", ""));
    },
    isSnapchatOffer() {
      return snapchatOffers.includes(this.$route.path.replace("/", ""));
    },
    isOffer() {
      return this.isFacebookOffer || this.isSnapchatOffer;
    },
  },
  mounted() {
    this.captureAffiliateQueryParams();
    this.affiliateSetup();

    this.dynamicEntryCount = this.getRandomInt(250, 1000);
    this.dynamicDayCount = this.getRandomInt(3, 20);

    this.startTime = Date.now();
    this.timeLeft = 1636000000000;
    //this.now =
    Storage.set("lr_campaign_id", 0);
    Storage.set("lr_click_id", 0);

    EventBus.$on("hide_logo", (d) => {
      this.showLogo = d;
    });

    // establish all the events for app.*
    EventBus.$on("app.progressBar.update", (newProgressUnit) => {
      this.progressBar = newProgressUnit;
    });
    EventBus.$on("app.stepChanged", (d) => {
      this.currentStep = d;
    });
    EventBus.$on("app.screenChanged", (d) => {
      window.__screen = d;
      this.screen = d;
      window.newScreen = d;
    });

    EventBus.$on("app.channelChanged", (d) => {
      window.__channel = d;
      this.channel = d;
      Storage.set("channel", d);
      window.newChannel = d;
    });

    EventBus.$on("app.registrationCompleted", (d) => {
      this.screen = "quote";
      this.quoteNumber = "#00" + Math.floor(Math.random() * (300 - 155) + 155);
      this.first_name = d.first_name;
      this.city = d.city;
      this.state = d.state;
    });
    EventBus.$on("app.coregOffers", (d) => {
      axios.post("https://essentialhealth.app/api/submit-leadgen-offers", {
        token: Backend.getCurrentUser().token,
        optins: d,
      });
    });
    // take the full search parameters
    Storage.set("app.queryParameters", this.$route.query);

    let ipAddress = Storage.get('app.clientIpAddress', false)
    let ipAddressData = Storage.get('app.ipAddressData', false)

    if (ipAddress && ipAddressData) {
      EventBus.$emit("app.postalCode.loaded", ipAddressData.postal);
    }

    else {
      axios.get("https://leadreserve.com/api/ip").then((d) => {
      Storage.set("app.clientIpAddress", d.data);
      
      axios
        .get(`https://ipinfo.io/${d.data}?token=e672d89ec968b1`)
        .then((k) => {
          if (k.data.country === 'US') {
            Storage.set("app.ipAddressData", k.data);
            EventBus.$emit("app.postalCode.loaded", k.data.postal);
          }
        });
      });
    }

    /**
     * Before everything...
     */
    this.$router.beforeEach((to, from, next) => {
      if (
        (from.path === "/survey-information" &&
          to.path === "/confirm-information") ||
        (from.path === "/confirm-questions" &&
          to.path === "/deal-calculator") ||
        (from.path === "/deal-calculator" && to.path === "/confirm-age")
      ) {
        this.exitIntentModal = true;
      } else {
        next();
      }
    });

    setInterval(() => {
      let formSubmitted = Storage.get('formSubmitted');
      if(formSubmitted === true) {
        this.blockPage = true;
      }
    }, 50);

    const keys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'gclid', 'gclsrc', 'dclid']
    const params = new URLSearchParams(window.location.search)

    keys.forEach((parameter) => {
      if (params.has(parameter)) {
        Storage.set(parameter, params.get(parameter))
      }
    })
  },
  created() {
    setTimeout(() => {
      var img = new Image(); 
      img.width = 0;
      img.height = 0;
      img.src = 'https://segment.prod.bidr.io/associate-segment?buzz_key=acquirecrowd&segment_key=acquirecrowd-10';
      document.body.appendChild(img);
    }, 250);

    setTimeout(() => {
      var img = new Image(); 
      img.width = 0;
      img.height = 0;
      img.src = 'https://segment.prod.bidr.io/associate-segment?buzz_key=acquirecrowd&segment_key=acquirecrowd-11';
      document.body.appendChild(img);
    }, 15000);
  },
  methods: {
    affiliateSetup() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params?.oid) {
        Storage.set("everflow.offer.id", params.oid);
      }
    },

    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },

    /**
     * Gotta pull the aff. params from
     * hash, because of history on vue-router
     */
    captureAffiliateQueryParams() {
      // set up the affiliate tracking object
      let affiliateTracking = {};

      // build out the hash string for params
      let hashString = window.location.hash;
      let parameters = hashString.substr(1, hashString.length).split("&");

      // load in the parameters
      parameters = parameters.map((i) => i.split("="));

      // set the affiliate tracking object
      parameters.forEach((i) => {
        affiliateTracking[i[0]] = i[1];
      });

      // store in localStorage for state
      Storage.set("affiliateTracking", affiliateTracking);
    },
  },
};
</script>

<style>
@import "assets/styles/components.css";
</style>
