<template>
  <div class="card">
    <h1 class="mt-4 text-2xl font-bold text-center">
      Complete 1 Offer Below 🎉
    </h1>

    <h2 class="mt-2 mb-6 text-sm text-center">
      To complete your entry please complete 1 offer.
    </h2>
    
    <div class="grid grid-cols-2 gap-6">
      <a
        :href="`https://www.abp4clk.com/8ZPDZF/GTSC31/${prePop}`"
        target="blank"
        class="h-64 shadow-xl hover:shadow-2xl rounded-3xl"
        @click="trackClick"
      >
        <img
          class="rounded-t-3xl"
          src="../../assets/walmart.png"
          alt=""
        >
        <div class="px-2.5 py-3">
          <h3 class="mb-1 text-xs font-bold">
            Get a $500 Walmart Gift Card for the Holidays.
          </h3>
          <p class="pr-2 text-xxs">
            Don't let Christmas Shopping break the bank!
          </p>
        </div>
      </a>
      <a
        :href="`https://www.abp4clk.com/8ZPDZF/GPMNT6/${prePop}`"
        target="blank"
        class="h-64 shadow-xl hover:shadow-2xl rounded-3xl"
        @click="trackClick"
      >
        <img
          class="rounded-t-3xl"
          src="../../assets/remote-jobs.png"
          alt=""
        >
        <div class="px-2.5 py-3">
          <h3 class="mb-1 text-xs font-bold">
            Get Paid at Home in Your Spare Time
          </h3>
          <p class="pr-2 text-xxs">Earn extra money by helping brands.</p>
        </div>
      </a>
      <a
        :href="`https://www.abp4clk.com/8ZPDZF/GN8S4K/${prePop}`"
        target="blank"
        class="h-64 shadow-xl hover:shadow-2xl rounded-3xl"
        @click="trackClick"
      >
        <img
          class="rounded-t-3xl"
          src="../../assets/iphone-offer.png"
          alt=""
        >
        <div class="px-2.5 py-3">
          <h3 class="mb-1 text-xs font-bold">Only Takes 2 Minutes to Enter</h3>
          <p class="pr-2 text-xxs">
            Enter for a chance to win the all new iPhone 14!
          </p>
        </div>
      </a>
      <a
        :href="`https://www.abp4clk.com/8ZPDZF/G95Q7P/${prePop}`"
        target="blank"
        class="h-64 shadow-xl hover:shadow-2xl rounded-3xl"
        @click="trackClick"
      >
        <img
          class="rounded-t-3xl"
          src="../../assets/apple-card.png"
          alt=""
        >
        <div class="px-2.5 py-3">
          <h3 class="mb-1 text-xs font-bold">Have You Ever used Apple Pay?</h3>
          <p class="pr-2 text-xxs">Get $500 sent directly to your iPhone</p>
        </div>
      </a>
      <a
        :href="`https://www.abp4clk.com/8ZPDZF/FNH5DF/${prePop}`"
        target="blank"
        class="h-64 shadow-xl hover:shadow-2xl rounded-3xl"
        @click="trackClick"
      >
        <img
          class="rounded-t-3xl"
          src="../../assets/extra-bucks.png"
          alt=""
        >
        <div class="px-2.5 py-3">
          <h3 class="mb-1 text-xs font-bold">Need Cash for Groceries?</h3>
          <p class="text-xxs">
            Get some extra bucks before your next paycheck.
          </p>
        </div>
      </a>
      <a
        :href="`https://www.abp4clk.com/8ZPDZF/F3SJK5/${prePop}`"
        target="blank"
        class="h-64 shadow-xl hover:shadow-2xl rounded-3xl"
        @click="trackClick"
      >
        <img
          class="rounded-t-3xl"
          src="../../assets/quiz.png"
          alt=""
        >
        <div class="px-2.5 py-3">
          <h3 class="mb-1 text-xs font-bold">Get Paid to Take a Quiz?</h3>
          <p class="text-xxs">Take a short quiz and get paid. That simple!</p>
        </div>
      </a>
    </div>

    <button
      :disabled="!offerCompleted"
      class="inline-flex justify-center w-full px-4 py-2 mt-5 text-sm font-bold text-white bg-blue-500 border border-blue-500 rounded-md shadow-sm hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
      @click="nextStep"
    >
      <svg
        v-if="isLoading"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="inline-block my-1 animate-spin"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
        />
        <path
          d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
        />
      </svg>

      <span v-else>Next</span>
    </button>
  </div>
</template>

<script>
import Storage from '@/storage'

export default {
  data() {
    return {
      offerCompleted: false,
    }
  },
  computed: {
    member() {
      return Storage.get('member')
    },
    prePop() {
      if (!this.member) {
        return ''
      }

      return `?zip=${this.member.zip_code}&dob_year=${this.member.dob_year}&address=${this.member.address}&phone=${this.member.phone_number}&dob_month=${this.member.dob_month}&last_name=${this.member.last_name}&first_name=${this.member.first_name}&dob_day=${this.member.dob_day}&email=${this.member.email_address}`
    }
  },
  methods: {
    trackClick() {
      if (window.gtag) {
        window.gtag('event', 'offer_clicked', {
          'event_category': 'funnel',
        });
      }

      this.offerCompleted = true
    },
    nextStep() {
      this.$router.push("/confirmation");
    },
  },
};
</script>