<template>
  <div class="card">
    <p class="text-black font-bold text-xl">
      Thank you for signing up!
    </p>
    <p class="text-gray-500">
      Our partners have selected the below deals for you!
    </p>

    <div class="mt-5">
      <a
        href="https://trupanion.avo2.net/c/2537534/503468/8654?s1=app.essentialhealthinfo.com"
        target="_blank"
      >
        <img
          src="../../assets/trupanion-dog-img.jpeg"
          class="w-full h-full object-center"
        >
      </a>
    </div>

    <div class="mt-5">
      <a
        href="https://click.linksynergy.com/fs-bin/click?id=aQJxtGmxI/U&offerid=719766.5&subid=0&type=4"
        target="_blank"
      >
        <img
          src="../../assets/relief-factor-img.jpg"
          class="w-full h-full object-center object-contain"
        >
      </a>
    </div>
  </div>
</template>

<script>
export default {
name: "LinkoutPage"
}
</script>

<style scoped>

</style>
