<template>
  <div class="card">
    <div class="p-2 bg-white">
      <p class="text-xs">
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          class="inline-block"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"
          />
          <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </svg>&nbsp;Monica says:
      </p>
      <p class="text-sm text-black-500">
        This is what I was able to find for you, <span style="text-transform: capitalize;">{{ currentUser.first_name }}</span>. I think you'll really like it.
      </p>
    </div>

    <div
      v-if="showModal"
      class="fixed z-10 inset-0 overflow-y-auto"
    >
      <div class="flex items-end justify-center min-h-screen pt-1 px-4 pb-80 text-center sm:block sm:p-0">
        <div
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <div
          class="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <!-- Heroicon name: exclamation -->
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  class="text-blue-500"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  id="modal-headline"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  Thank you!
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Your personalized quote is on it's way. To confirm a few details, please <strong>call in to our hot line</strong>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <a
              href="tel:18887171672"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              +1-888-717-1672
            </a>
            <button
              type="button"
              class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              @click="showModal = false"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="p-5 m-3 bg-white shadow-xl block text-left">
      <img
        src="../../assets/oscarLogo.webp"
        class="w-20 h-20 object-contain"
      >
      <p class="text-gray-500 text-xs mb-2">
        Oscar Health Insurance is a technology-driven health insurance company founded in 2012, and is headquartered in New York City.
      </p>
      <a
        href="tel:+18887171672"
        class="text-xs reg-button ml-0"
      >Learn More</a>
      <!--      <span @click="showModal = true" class="text-xs reg-button ml-0">Learn More</span>-->
    </div>

    <div class="p-5 m-3 bg-white shadow-xl block text-left">
      <img
        src="../../assets/kaiserLogo.jpg"
        class="w-20 h-20 object-contain"
      >
      <p class="text-gray-500 text-xs mb-2">
        Kaiser Permanente is an American integrated managed care consortium, based in Oakland, California, United States, founded in 1945 by industrialist Henry J. Kaiser and physician Sidney Garfield.
      </p>
      <a
        href="tel:+18887171672"
        class="text-xs reg-button ml-0"
      >Learn More</a>
      <!--      <span @click="showModal = true" class="text-xs reg-button ml-0">Learn More</span>-->
    </div>

    <div class="p-5 m-3 bg-white shadow-xl block text-left">
      <img
        src="../../assets/unitedHealthcareLogo.png"
        class="w-20 h-20 object-contain"
      >
      <p class="text-gray-500 text-xs mb-2">
        UnitedHealth Group Incorporated is an American for-profit managed health care company based in Minnetonka, Minnesota. It offers health care products and insurance services.
      </p>
      <a
        href="tel:+18887171672"
        class="text-xs reg-button ml-0"
      >Learn More</a>
      <!--      <span @click="showModal = true" class="text-xs reg-button ml-0">Learn More</span>-->
    </div>
  </div>
</template>

<script>
import Backend from "@/backend";

export default {
  name: "TopQuotes",
  data() {
    return {
      showModal: false,
      currentUser: {}
    }
  },
  mounted() {
    this.currentUser = Backend.getCurrentUser();
    setTimeout(() => {
      this.$router.push('/thank-you-for-interest');
    },5000);
  }
}
</script>

<style scoped>

</style>
