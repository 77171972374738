<template>
  <div class="mx-5 sm:mx-0">
    <div class="my-10">
      <img
        src="../assets/ehi-logo-white.png"
        style="width: 270px"
        class="m-auto h-15 mt-0 mb-0"
      >
    </div>

    <div
      style="margin-top: 0px"
      class="
        md:w-96 md:m-auto
        mb-20
        px-2
        pt-4
        mt-10
        bg-white
        overflow-hidden
        rounded-3xl
        shadow-lg
      "
    >
      <div class="px-4 pb-4">
        <div class="flex flex-col items-center">
          <p
            class="
              text-2xl
              my-3
              md:font-sans
              uppercase
              text-center text-blue-900
              tracking-widest
              font-bold
            "
          >
            Welcome!
          </p>

          <img
            :src="require(`../assets/${currentImage}`)"
            :class="currentClass"
          >

          <button
            class="mt-15 mt-5 mb-3 submit-button border-0 rounded-m bg-blue-500"
            @click="next"
          >
            <span class="text-lg"> Continue </span>
          </button>
        </div>
      </div>
    </div>

    <center>
      <p
        class="mt-8 text-blue-900"
        style="font-size: 0.6rem"
      >
        <a
          href="http://app.essentialhealthinfo.com/privacy-policy"
          class="text-underline inline-block m-1"
        >Privacy Policy</a>
        •
        <a
          href="http://app.essentialhealthinfo.com/termsandconditions"
          class="text-underline inline-block m-1"
        >Terms And Conditions</a>
        •
        <a
          href="http://app.essentialhealthinfo.com/ccpa-do-not-sell-my-information"
          class="text-underline inline-block m-1"
        >Do Not Sell My Information</a><br>
        <a
          href="http://app.essentialhealthinfo.com/ccpa-your-california-privacy-rights"
          class="text-underline inline-block m-1"
        >California Residents</a>
        •
        <a
          href="/sweepstakes-rules"
          class="text-underline inline-block m-1"
        >Sweepstakes Rules</a>
      </p>
    </center>
  </div>
</template>

<script>
import { sweeps } from "@/constants/sweeps";

export default {
  props: {
    sweep: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentPath: function () {
      return sweeps?.[this.sweep].path;
    },
    currentImage: function () {
      return sweeps?.[this.sweep].image;
    },
    currentClass: function () {
      return sweeps?.[this.sweep].class;
    },
  },
  mounted() {
    if (!sweeps?.[this.sweep]) {
      this.$router.push("/not-found");
    }
  },
  methods: {
    next: function () {
      this.$router.push(this.currentPath);
    },
  },
};
</script>