<template>
  <div class="card">
    <div class="mt-5 text-center">
      <p class="text-xl font-bold text-black-500">
        Enter to win a Titan Telescoping Flagpole!
      </p>
      <div class="p-5">
        <img
          src="../../assets/titan-telescoping-flagpole.png"
          class="block h-56 m-auto"
        />
      </div>
      <p class="mt-5 text-gray-500">
        Enter your information below and start our short health survey to enter
        and have a chance at winning.
      </p>
    </div>

    <div class="mt-5 text-left">
      <p class="font-semibold text-black-500">Postal Code*</p>
      <input
        v-model="postalCode"
        v-mask="'#####'"
        pattern="[0-9]*"
        type="text"
        :class="postalCodeError ? 'reg-input border-red-500' : 'reg-input'"
        placeholder="92656"
      />
      <p v-if="postalCodeError" class="mt-1 text-xs text-red-500">
        Invalid zip code!
      </p>

      <p class="mt-3 font-semibold text-black-500">First Name*</p>
      <input
        v-model="firstName"
        class="
          block
          w-full
          p-2
          mt-1
          border border-gray-300
          rounded-lg
          shadow-md
          focus:outline-none
          focus:ring-2
          focus:ring-blue-500
          focus:border-transparent
        "
        placeholder="John"
      />

      <p class="mt-3 font-semibold text-black-500">Last Name*</p>
      <input
        v-model="lastName"
        class="
          block
          w-full
          p-2
          mt-1
          border border-gray-300
          rounded-lg
          shadow-md
          focus:outline-none
          focus:ring-2
          focus:ring-blue-500
          focus:border-transparent
        "
        placeholder="Doe"
      />
    </div>

    <button
      :disabled="!validationIsTrue"
      class="
        inline-flex
        justify-center
        w-full
        px-4
        py-2
        mt-5
        text-sm
        font-bold
        text-white
        bg-blue-500
        border border-blue-500
        rounded-md
        shadow-sm
        hover:bg-gray-50
        focus:outline-none
      "
      @click="nextStep"
    >
      <svg
        v-if="isLoading"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="inline-block animate-spin"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
        />
        <path
          d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
        /></svg
      >&nbsp;Next Step
    </button>

    <p class="text-gray-400 text-center text-xs mt-5">
      By clicking "Next Step" you are agreeing to our
      <a class="underline font-bold text-blue-400" href="/privacy-policy"
        >privacy policy</a
      >
      and
      <a class="underline font-bold text-blue-400" href="/sweepstakes-rules"
        >sweepstake rules</a
      >.
    </p>
  </div>
</template>

<script>
import { EventBus } from "@/eventbus";
import Storage from "@/storage";
import Backend from "@/backend";
import axios from "axios";
export default {
  name: "AppleWatchPage",
  data() {
    return {
      isLoading: false,
      postalCode: "",
      postalCodeError: false,
      firstName: "",
      lastName: "",
      colorChangeOne: "#000",
      colorChangeTwo: "#000",
      colorChangeThree: "#000",
    };
  },
  watch: {
    postalCode(oldVal, newVal) {
      if (newVal !== "") {
        this.colorChangeOne = "#3b82f6";
      }
    },
    firstName(oldVal, newVal) {
      if (newVal !== "") {
        this.colorChangeTwo = "#3b82f6";
      }
    },
    lastName(oldVal, newVal) {
      if (newVal !== "") {
        this.colorChangeThree = "#3b82f6";
      }
    },
  },
  mounted() {
    EventBus.$on("app.postalCode.loaded", (d) => {
      this.postalCode = d;
    });
    this.$nextTick(() => {
      EventBus.$emit("app.screenChanged", "giveaway");
    });
  },
  methods: {
    validationIsTrue() {
      return (
        this.postalCode !== "" && this.firstName !== "" && this.lastName !== ""
      );
    },
    nextStep() {
      this.isLoading = true;
      axios
        .get(
          "https://essentialhealth.app/api/lookup-zipcode?postalCode=" +
            this.postalCode
        )
        .then((d) => {
          if (
            Object.prototype.hasOwnProperty.call(d.data["ZipCode"], "Error")
          ) {
            this.postalCodeError = true;
            this.isLoading = false;
          } else {
            Storage.set("app.first_step", {
              postal_code: this.postalCode,
              city: d.data["ZipCode"]["City"],
              state: d.data["ZipCode"]["State"],
              first_name: this.firstName,
              last_name: this.lastName,
            });
            Backend.trackBeeswax(4);
            EventBus.$emit("app.progressBar.update", 50);
            this.$router.push("/confirm-age");
          }
        });
    },
  },
};
</script>

<style scoped></style>