import PrePopSubmission from "@/pages/PrePopSubmission";
import PostCodeAndName from "@/components/form/PostCodeAndName";
import DateOfBirth from "@/components/form/DateOfBirth";
import AdditionalQuestions from "@/components/form/AdditionalQuestions";
import InformationOptIn from "@/components/form/InformationOptIn";
import TopQuotes from "@/components/quotes/TopQuotes";
import InterestInOffers from "@/components/form/InterestInOffers";
import ThankYouForSigningUp from "@/components/form/ThankYouForSigningUp";
import BowflexPage from "@/components/form/BowflexPage";
import LululeomPage from "@/components/form/LululemonPage";
import SocialPage from "@/components/form/SocialPage";
import HypervoltPage from "@/components/form/HypervoltPage";
import FreeHealthCarePage from "@/components/form/FreeHealthCarePage";
import HydrorowPage from "@/components/form/HydrorowPage";
import LinkoutPage from "@/components/form/LinkoutPage";
import MirrorPage from "./components/form/MirrorPage";
import CoRegPage from "@/components/form/CoRegPage";
import BadRating from "@/components/form/BadRating";
import InsuranceInfo from "@/components/form/InsuranceInfo";
import HealthCalculator from "@/components/form/HealthCalculator";
import ConfirmationPage from "@/components/form/ConfirmationPage";
import VerifyInformation from "@/components/form/VerifyInformation";
import PushSubscription from "@/components/form/PushSubscription";
import Midpath from "@/components/form/Midpath.vue";
import MidpathNext from "@/components/form/MidpathNext.vue";
import Supe73 from "@/components/form/Supe73.vue";
import EBike from "@/components/form/EBike";
import SmartWatch from "@/components/form/SmartWatch";
// import SmartWatchS from "@/components/form/SmartWatchS";
import Laptop from "@/components/form/Laptop";
// import LaptopS from "@/components/form/LaptopS";
import KitchenWare from "@/components/form/KitchenWare";
import TrifectaMeals from "@/components/form/TrifectaMeals";
import FreshlyMeals from "@/components/form/FreshlyMeals";
import AerisAair from "@/components/form/AerisAair";
import FitBit from "@/components/form/FitBit";
import OuraRing from "@/components/form/OuraRing";
// import OuraRingS from "@/components/form/OuraRingS";
import CurrentProvider from "@/components/form/CurrentProvider";
import DentalInsurance from "@/components/form/DentalInsurance";
import PurplePillow from "@/components/form/PurplePillow";
// import PurplePillowS from "@/components/form/PurplePillowS";
// import LeCreusetS from "@/components/form/LeCreusetS";
import LeCreuset from "@/components/form/LeCreuset";
// import UpliftS from "@/components/form/UpliftS";
import Uplift from "@/components/form/Uplift";
import Gate from "@/components/form/Gate";
// import SubwayS from "@/components/form/SubwayS";
import Subway from "@/components/form/Subway";
import AirFryer from "@/components/form/AirFryer";
// import AirFryerS from "@/components/form/AirFryerS";
import Roomba from "@/components/form/Roomba";
// import RoombaS from "@/components/form/RoombaS";
// import DysonS from "@/components/form/DysonS";
import Dyson from "@/components/form/Dyson";
// import YetiS from "@/components/form/YetiS";
import Yeti from "@/components/form/Yeti";
// import rangeS from "@/components/form/rangeS";
import range from "@/components/form/range";
// import InstantPotS from "@/components/form/InstantPotS";
import InstantPot from "@/components/form/InstantPot";
// import testkitS from "@/components/form/testkitS";
import testkit from "@/components/form/testkit";
// import ManscapedS from "@/components/form/ManscapedS";
import Manscaped from "@/components/form/Manscaped";
// import GrillS from "@/components/form/GrillS";
import Grill from "@/components/form/Grill";
// import CubePillowS from "@/components/form/CubePillowS";
import CubePillow from "@/components/form/CubePillow";
// import BeatsS from "@/components/form/BeatsS";
import Beats from "@/components/form/Beats";
import Switch from "@/components/form/Switch";
import JBL from "@/components/form/JBL";
import Panga from "@/components/form/Panga";
import HyperVolt from "@/components/form/HyperVolt";
import IpadAir from "@/components/form/IpadAir";
import Nest from "@/components/form/Nest";
import Blackstone from "@/components/form/Blackstone";
import BlackStoneA from "@/components/form/BlackStoneA";
import Fryer from "@/components/form/Fryer";
import greenEgg from "@/components/form/greenEgg";
import TreeTent from "@/components/form/TreeTent";
import GolfSet from "@/components/form/GolfSet";
import Smoker from "@/components/form/Smoker";
import BioLite from "@/components/form/BioLite";
import Bike from "@/components/form/Bike";
import Karaoke from "@/components/form/Karaoke";
import WaffleMaker from "@/components/form/WaffleMaker";
import MikoFoot from "@/components/form/MikoFoot";
import JuiceFountian from "@/components/form/JuiceFountian";
import FitBitVersa from "@/components/form/FitBitVersa";
import LazyBoy from "@/components/form/LazyBoy";
import FlairEspresso from "@/components/form/FlairEspresso";
import LazyBoyCaseyRockingRecliner from "@/components/form/LazyBoyCaseyRockingRecliner";
import AppleHomePod from "@/components/form/AppleHomePod";
import FlairEspressoMaker from "@/components/form/FlairEspressoMaker";
import HomeDepotGiftcard from "@/components/form/HomeDepotGiftcard";
import DogBed from "@/components/form/DogBed";
import TelescopingFlagpole from "@/components/form/TelescopingFlagpole";
import KickScooter from "@/components/form/KickScooter";
import WiFiProjector from "@/components/form/WiFiProjector";
import StereoTurntable from "@/components/form/StereoTurntable";
import BassGuitar from "@/components/form/BassGuitar";
import MasterGater from "@/components/MasterGater";
import ThankYou from "@/components/form/ThankYou";
import HighChair from "@/components/form/HighChair";
import WaveCrib from "@/components/form/WaveCrib";
import CompleteStroller from "@/components/form/CompleteStroller";
import Offers from "@/components/form/Offers";

const routes = [
  {
    path: "/thankyou",
    component: ThankYou,
  },
  {
    path: "/gate",
    component: Gate,
  },
  {
    path: "/master-gater/:sweep",
    props: true,
    component: MasterGater,
  },
  {
    path: "/gate-subway",
    component: Gate,
    props: {
      route: "/subway",
    },
  },
  {
    path: "/gate-versa",
    component: Gate,
    props: {
      route: "/versa",
    },
  },
  {
    path: "/gate-smoker",
    component: Gate,
    props: {
      route: "/smoker",
    },
  },
  {
    path: "/gate-biolite",
    component: Gate,
    props: {
      route: "/biolite",
    },
  },
  {
    path: "/gate-bike",
    component: Gate,
    props: {
      route: "/bike",
    },
  },
  {
    path: "/gate-karaoke",
    component: Gate,
    props: {
      route: "/karaoke",
    },
  },
  {
    path: "/gate-waffle",
    component: Gate,
    props: {
      route: "/waffle",
    },
  },
  {
    path: "/gate-miko",
    component: Gate,
    props: {
      route: "/miko",
    },
  },
  {
    path: "/gate-juicer",
    component: Gate,
    props: {
      route: "/juicer",
    },
  },
  {
    path: "/gate-lazy",
    component: Gate,
    props: {
      route: "/lazy",
    },
  },
  {
    path: "/gate-flair",
    component: Gate,
    props: {
      route: "/flair",
    },
  },
  {
    path: "/gate-golf-set",
    component: Gate,
    props: {
      route: "/golf-set",
    },
  },
  {
    path: "/gate-cube",
    component: Gate,
    props: {
      route: "/cube",
    },
  },
  {
    path: "/gate-egg",
    component: Gate,
    props: {
      route: "/egg",
    },
  },
  {
    path: "/gate-beats-s",
    component: Gate,
    props: {
      route: "/beats-s",
    },
  },
  {
    path: "/gate-switch",
    component: Gate,
    props: {
      route: "/switch",
    },
  },
  {
    path: "/gate-accessory",
    component: Gate,
    props: {
      route: "/accessory",
    },
  },
  {
    path: "/gate-fryer",
    component: Gate,
    props: {
      route: "/fryer",
    },
  },
  {
    path: "/gate-tent",
    component: Gate,
    props: {
      route: "/tent",
    },
  },
  {
    path: "/gate-beats",
    component: Gate,
    props: {
      route: "/beats",
    },
  },
  {
    path: "/gate-panga",
    component: Gate,
    props: {
      route: "/panga",
    },
  },
  {
    path: "/gate-jbl",
    component: Gate,
    props: {
      route: "/jbl",
    },
  },
  {
    path: "/gate-cube-s",
    component: Gate,
    props: {
      route: "/cube-s",
    },
  },
  {
    path: "/gate-testkit",
    component: Gate,
    props: {
      route: "/testkit",
    },
  },
  {
    path: "/gate-testkit-s",
    component: Gate,
    props: {
      route: "/testkit-s",
    },
  },
  {
    path: "/gate-range",
    component: Gate,
    props: {
      route: "/range",
    },
  },
  {
    path: "/gate-range-s",
    component: Gate,
    props: {
      route: "/range-s",
    },
  },
  {
    path: "/gate-lm-s",
    component: Gate,
    props: {
      route: "/manscaped-s",
    },
  },
  {
    path: "/gate-lm",
    component: Gate,
    props: {
      route: "/manscaped",
    },
  },
  {
    path: "/gate-gf-s",
    component: Gate,
    props: {
      route: "/grill-s",
    },
  },
  {
    path: "/gate-gf",
    component: Gate,
    props: {
      route: "/grill",
    },
  },
  {
    path: "/gate-instantpot",
    component: Gate,
    props: {
      route: "/instantpot",
    },
  },
  {
    path: "/gate-instantpot-s",
    component: Gate,
    props: {
      route: "/instantpot-s",
    },
  },
  {
    path: "/gate-dyson",
    component: Gate,
    props: {
      route: "/dyson",
    },
  },
  {
    path: "/gate-blackstone",
    component: Gate,
    props: {
      route: "/blackstone",
    },
  },
  {
    path: "/gate-airfryer",
    component: Gate,
    props: {
      route: "/airfryer",
    },
  },
  {
    path: "/gate-stc",
    component: Gate,
    props: {
      route: "/e-bike",
    },
  },
  {
    path: "/gate-lt",
    component: Gate,
    props: {
      route: "/laptop",
    },
  },
  {
    path: "/gate-aws",
    component: Gate,
    props: {
      route: "/smart-watch",
    },
  },
  {
    path: "/gate-fbv",
    component: Gate,
    props: {
      route: "/fit-bit",
    },
  },
  {
    path: "/gate-osr",
    component: Gate,
    props: {
      route: "/oura-ring",
    },
  },

  {
    path: "/gate-kw",
    component: Gate,
    props: {
      route: "/kitchen-ware",
    },
  },
  {
    path: "/gate-aa",
    component: Gate,
    props: {
      route: "/aeris-aair",
    },
  },
  {
    path: "/gate-uplift",
    component: Gate,
    props: {
      route: "/standing-desk",
    },
  },
  {
    path: "/gate-lc",
    component: Gate,
    props: {
      route: "/le-creuset",
    },
  },
  {
    path: "/gate-supe",
    component: Gate,
    props: {
      route: "/supe73-bike",
    },
  },

  //SnapChat
  {
    path: "/gate-s",
    component: Gate,
    props: {
      route: "/purple-pillow-s",
    },
  },
  {
    path: "/gate-subway-s",
    component: Gate,
    props: {
      route: "/subway-s",
    },
  },
  {
    path: "/gate-dyson-s",
    component: Gate,
    props: {
      route: "/dyson-s",
    },
  },
  {
    path: "/gate-airfryer-s",
    component: Gate,
    props: {
      route: "/airfryer-s",
    },
  },
  {
    path: "/gate-roomba",
    component: Gate,
    props: {
      route: "/roomba",
    },
  },
  {
    path: "/gate-roomba-s",
    component: Gate,
    props: {
      route: "/roomba-s",
    },
  },
  {
    path: "/gate-yeti-s",
    component: Gate,
    props: {
      route: "/yeti-s",
    },
  },
  {
    path: "/gate-nest",
    component: Gate,
    props: {
      route: "/nest",
    },
  },
  {
    path: "/gate-ipad",
    component: Gate,
    props: {
      route: "/ipad",
    },
  },
  {
    path: "/gate-volt",
    component: Gate,
    props: {
      route: "/volt",
    },
  },
  {
    path: "/gate-yeti",
    component: Gate,
    props: {
      route: "/yeti",
    },
  },
  {
    path: "/gate-lts",
    component: Gate,
    props: {
      route: "/laptop-s",
    },
  },
  {
    path: "/gate-aws-s",
    component: Gate,
    props: {
      route: "/smart-watch-s",
    },
  },
  {
    path: "/gate-aws-s",
    component: Gate,
    props: {
      route: "/smart-watch-s",
    },
  },
  {
    path: "/gate-osr-s",
    component: Gate,
    props: {
      route: "/oura-ring-s",
    },
  },
  {
    path: "/gate-uplift-s",
    component: Gate,
    props: {
      route: "/standing-desk-s",
    },
  },
  {
    path: "/gate-lc-s",
    component: Gate,
    props: {
      route: "/le-creuset-s",
    },
  },
  {
    path: "/gate-lazyboy",
    component: Gate,
    props: {
      route: "/lazyboy",
    },
  },
  {
    path: "/gate-apple-homepod",
    component: Gate,
    props: {
      route: "/apple-homepod",
    },
  },
  {
    path: "/gate-flair-espresso",
    component: Gate,
    props: {
      route: "/flair-espresso",
    },
  },
  {
    path: "/gate-home-depot-giftcard",
    component: Gate,
    props: {
      route: "/home-depot-giftcard",
    },
  },
  {
    path: "/gate-yeti-trailhead-two-in-one-dog-bed",
    component: Gate,
    props: {
      route: "/yeti-trailhead-two-in-one-dog-bed",
    },
  },
  {
    path: "/gate-titan-telescoping-flagpole",
    component: Gate,
    props: {
      route: "/titan-telescoping-flagpole",
    },
  },
  {
    path: "/gate-electric-kick-scooter",
    component: Gate,
    props: {
      route: "/flair-espresso",
    },
  },
  {
    path: "/gate-electric-kick-scooter",
    component: Gate,
    props: {
      route: "/flair-espresso",
    },
  },
  {
    path: "/gate-5g-wifi-projector",
    component: Gate,
    props: {
      route: "/5g-wifi-projector",
    },
  },
  {
    path: "/gate-bluetooth-stereo-turntable",
    component: Gate,
    props: {
      route: "/bluetooth-stereo-turntable",
    },
  },

  {
    path: "/gate-yamaha-4-string-bass-guitar",
    component: Gate,
    props: {
      route: "/yamaha-4-string-bass-guitar",
    },
  },
  {
    path: "/health-calculator",
    component: HealthCalculator,
  },
  {
    path: "/blackstone",
    component: Blackstone,
  },
  {
    path: "/standing-desk",
    component: Uplift,
  },
  {
    path: "/standing-desk-s",
    component: Uplift,
  },
  {
    path: "/le-creuset",
    component: LeCreuset,
  },
  {
    path: "/le-creuset-s",
    component: LeCreuset,
  },
  {
    path: "/grill-s",
    component: Grill,
  },
  {
    path: "/grill",
    component: Grill,
  },
  {
    path: "/manscaped-s",
    component: Manscaped,
  },
  {
    path: "/high-chair",
    component: HighChair,
  },
  {
    path: "/complete-stroller",
    component: CompleteStroller,
  },
  {
    path: "/wave-crib",
    component: WaveCrib,
  },
  {
    path: "/nest",
    component: Nest,
  },
  {
    path: "/ipad",
    component: IpadAir,
  },
  {
    path: "/volt",
    component: HyperVolt,
  },
  {
    path: "/manscaped",
    component: Manscaped,
  },

  {
    path: "/dyson-s",
    component: Dyson,
  },
  {
    path: "/dyson",
    component: Dyson,
  },
  {
    path: "/cube-s",
    component: CubePillow,
  },
  {
    path: "/cube",
    component: CubePillow,
  },
  {
    path: "/beats-s",
    component: Beats,
  },
  {
    path: "/beats",
    component: Beats,
  },
  {
    path: "/testkit",
    component: testkit,
  },
  {
    path: "/testkit-s",
    component: testkit,
  },
  {
    path: "/instantpot-s",
    component: InstantPot,
  },
  {
    path: "/instantpot",
    component: InstantPot,
  },
  {
    path: "/subway",
    component: Subway,
  },
  {
    path: "/subway-s",
    component: Subway,
  },
  {
    path: "/roomba-s",
    component: Roomba,
  },
  {
    path: "/roomba",
    component: Roomba,
  },
  {
    path: "/airfryer",
    component: AirFryer,
  },
  {
    path: "/airfryer-s",
    component: AirFryer,
  },
  {
    path: "/yeti-s",
    component: Yeti,
  },
  {
    path: "/yeti",
    component: Yeti,
  },
  {
    path: "/jbl",
    component: JBL,
  },
  {
    path: "/panga",
    component: Panga,
  },
  {
    path: "/accessory",
    component: BlackStoneA,
  },
  {
    path: "/fryer",
    component: Fryer,
  },
  {
    path: "/tent",
    component: TreeTent,
  },
  {
    path: "/golf-set",
    component: GolfSet,
  },
  {
    path: "/smoker",
    component: Smoker,
  },
  {
    path: "/biolite",
    component: BioLite,
  },
  {
    path: "/bike",
    component: Bike,
  },
  {
    path: "/versa",
    component: FitBitVersa,
  },
  {
    path: "/karaoke",
    component: Karaoke,
  },
  {
    path: "/waffle",
    component: WaffleMaker,
  },
  {
    path: "/miko",
    component: MikoFoot,
  },
  {
    path: "/juicer",
    component: JuiceFountian,
  },
  {
    path: "/lazy",
    component: LazyBoy,
  },
  {
    path: "/flair",
    component: FlairEspresso,
  },
  {
    path: "/range",
    component: range,
  },
  {
    path: "/egg",
    component: greenEgg,
  },
  {
    path: "/range-s",
    component: range,
  },
  {
    path: "/switch",
    component: Switch,
  },

  {
    path: "/fit-bit",
    component: FitBit,
  },
  {
    path: "/laptop-s",
    component: Laptop,
  },
  {
    path: "/purple-pillow",
    component: PurplePillow,
  },
  {
    path: "/purple-pillow-s",
    component: PurplePillow,
  },
  {
    path: "/home-depot-giftcard",
    component: HomeDepotGiftcard,
  },
  {
    path: "/yeti-trailhead-two-in-one-dog-bed",
    component: DogBed,
  },
  {
    path: "/titan-telescoping-flagpole",
    component: TelescopingFlagpole,
  },
  {
    path: "/electric-kick-scooter",
    component: KickScooter,
  },
  {
    path: "/5g-wifi-projector",
    component: WiFiProjector,
  },
  {
    path: "/bluetooth-stereo-turntable",
    component: StereoTurntable,
  },
  {
    path: "/yamaha-4-string-bass-guitar",
    component: BassGuitar,
  },
  {
    path: "/ccpa-your-california-privacy-rights",
    name: "california",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/pages/PrivacyRights.vue"),
  },
  {
    path: "/ccpa-do-not-sell-my-information",
    name: "dontSell",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/pages/DontSell.vue"),
  },
  {
    path: "/sweepstakes-rules",
    name: "sweeps",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/pages/Sweeps.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/pages/PrivacyPolicy.vue"),
  },
  {
    path: "/termsandconditions",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/pages/Terms.vue"),
  },
  {
    path: "/current-provider",
    component: CurrentProvider,
  },
  {
    path: "/oura-ring",
    component: OuraRing,
  },
  {
    path: "/oura-ring-s",
    component: OuraRing,
  },
  {
    path: "/dental-insurance",
    component: DentalInsurance,
  },
  {
    path: "/trifecta-meals",
    component: TrifectaMeals,
  },
  {
    path: "/freshly-meals",
    component: FreshlyMeals,
  },
  {
    path: "/aeris-aair",
    component: AerisAair,
  },
  {
    path: "/e-bike",
    component: EBike,
  },
  {
    path: "/kitchen-ware",
    component: KitchenWare,
  },
  {
    path: "/smart-watch",
    component: SmartWatch,
  },
  {
    path: "/smart-watch-s",
    component: SmartWatch,
  },
  {
    path: "/laptop",
    component: Laptop,
  },
  {
    path: "/supe73-bike",
    component: Supe73,
  },
  {
    path: "/lazyboy",
    component: LazyBoyCaseyRockingRecliner,
  },
  {
    path: "/apple-homepod",
    component: AppleHomePod,
  },
  {
    path: "/flair-espresso",
    component: FlairEspressoMaker,
  },
  {
    path: "/",
    component: PostCodeAndName,
  },
  {
    path: "/sign-up",
    component: SocialPage,
  },
  {
    path: "/confirm-age",
    component: DateOfBirth,
  },
  {
    path: "/deal-calculator",
    component: BadRating,
  },
  {
    path: "/insurance-info",
    component: InsuranceInfo,
  },
  {
    path: "/confirm-questions",
    component: AdditionalQuestions,
  },
  {
    path: "/confirm-information",
    component: InformationOptIn,
  },
  {
    path: "/thank-you",
    component: ThankYouForSigningUp,
  },
  {
    path: "/confirm-interest",
    component: InterestInOffers,
  },
  {
    path: "/quotes",
    component: TopQuotes,
  },
  {
    path: "/thank-you-for-interest",
    component: LinkoutPage,
  },

  // marketing pages
  {
    path: "/bowflex-giveaway",
    component: BowflexPage,
  },
  {
    path: "/lululemon-giveaway",
    component: LululeomPage,
  },
  {
    path: "/hypervolt-giveaway",
    component: HypervoltPage,
  },
  {
    path: "/free-health-care",
    component: FreeHealthCarePage,
  },
  {
    path: "/hydrow-rower",
    component: HydrorowPage,
  },
  {
    path: "/mirror-giveaway",
    component: MirrorPage,
  },
  {
    path: "/survey-information",
    component: CoRegPage,
  },
  {
    path: "/confirmation",
    name: "confirmation",
    component: ConfirmationPage,
  },
  {
    path: "/verify-information",
    name: "verify-information",
    component: VerifyInformation,
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: PushSubscription,
  },
  {
    path: "/pp-submission",
    component: PrePopSubmission,
  },
  {
    path: "/offers",
    name: "offers",
    component: Offers,
  },
  {
    path: "/midpath",
    name: "midpath",
    component: Midpath,
  },
  {
    path: "/midpath-next",
    name: "midpath-next",
    component: MidpathNext,
  },
];

export default routes;
