<template>
  <div class="card">
    <div class="text-left">
      <p class="mt-6 text-gray-500 font-bold text-xs mb-4">
        😃 Almost there, {{ firstName }}!
      </p>

      <p class="mt-3 text-black-500 font-semibold">
        Insurance Provider*
      </p>
      <p class="text-gray-500 mb-2">
        Start typing and it will search:
      </p>

      <div class="relative">
        <input
          v-model="provider"
          name="insurance_provider"
          class="mt-1 p-2 border-2 border-gray-300 rounded-lg w-full block  focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
          placeholder="AARP"
        >

        <div
          v-if="provider.length >= 2 && !isFound"
          class="absolute w-full shadow-lg"
          style="z-index: 1000;"
        >
          <div class="card bg-white">
            <div
              v-for="entry in filteredSearchEntries"
              :key="entry"
              class="card-entry p-2 hover:bg-gray-100 focus:bg-gray-100"
              @click="setEntry(entry)"
            >
              <p class="text-gray-500">
                {{ entry }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <p
        class="mt-2 font-semibold text-blue-500 text-xs text-underline"
        @click="cantFind()"
      >
        I can't find my provider
      </p>

      <div class="mt-5">
        <p class="mt-3 text-black-500 font-semibold">
          What best represents your yearly income?
        </p>
        <p class="text-gray-500 mb-2">
          Make a selection below:
        </p>

        <div>
          <button
            :class="`${number === 12000 ? 'reg-button-selected' : 'reg-button'} inline-block `"
            @click="number = 12000"
          >
            $12k
          </button>
          <button
            :class="`${number === 25000 ? 'reg-button-selected ' : 'reg-button'} inline-block`"
            @click="number = 25000"
          >
            $25k
          </button>
          <button
            :class="`${number === 50000 ? 'reg-button-selected ' : 'reg-button'} inline-block`"
            @click="number = 50000"
          >
            $50k
          </button>
          <button
            :class="`${number === 75000 ? 'reg-button-selected ' : 'reg-button'} inline-block`"
            @click="number = 75000"
          >
            $75k+
          </button>
        </div>
      </div>


      <button
        class="submit-button mt-5  border-0"
        @click="nextStep"
      >
        <svg
          v-if="isLoading"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          class="inline-block animate-spin"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
          />
          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
        </svg>&nbsp;Confirm Provider and Income
      </button>
    </div>
  </div>
</template>

<script>
import Backend from "@/backend";
import Storage from "@/storage";
import {EventBus} from "@/eventbus";
export default {
  name: "ConfirmInsuranceProvider",
  data: () => ({
    firstName: 'let\'s go',
    searchEntries: [
      'Trico Electric Cooperative',
      'Windrose Energy',
      'AARP',
      'AETNA',
      'Aflac',
      'American Family',
      'Anthem',
      'Armed Forces Insurance',
      'Assurant',
      'Blue Cross Blue Shield',
      'Cigna',
      'Golden Rule',
      'Government Employees',
      'Hartford AARP',
      'Health Net',
      'Health Plus of America',
      'HealthMarkets',
      'Humana',
      'InSphere',
      'Kaiser Permanente',
      'LifeWise Health Plan',
      'Metlife Insurance',
      'Mutual of Omaha',
      'Oxford',
      'Principal Financial',
      'State Farm',
      'Tricare',
      'UnitedHealthCare',
      'USAA',
      'Wellpoint',
      'Other',
    ],
    filteredNumberEntries: [


    ],
    provider: "",
    validationIsTrue: false,
    isLoading: false,
    isFound: false,
    number: 0,
  }),
  computed: {
    filteredSearchEntries() {
      return this.searchEntries.filter(i => i.toLowerCase().includes(this.provider.toLowerCase()));
    }
  },
  mounted() {
    EventBus.$emit('app.stepChanged',3);

    let data = this.parseLocationToKeyValueArray();
    let userData = {}; 
    data.map(i => userData[i[0]] = i[1]);
    if(Object.prototype.hasOwnProperty.call(userData, 'first_name')) {
      EventBus.$emit('hide_logo', false);
      Storage.set('app.first_step', {
        postal_code:  userData['postal_code'],
        city:         userData['city'],
        state:        userData['state'],
        first_name:   userData['first_name'],
        last_name:    userData['last_name'],
      });

      Storage.set('user.first_name',  userData['first_name']);

      Storage.set('user.city',      userData['city']);
      Storage.set('user.zip_code',  userData['postal_code']);
    }

    this.$nextTick(() => {
      this.firstName = Storage.get('user.first_name');
    });
     window.dotq = window.dotq || [];
      window.dotq.push(
      {
        'projectId': '10000',
        'properties': {
          'pixelId': '10156295',
          'qstrings': {
            'et': 'custom',
            'ec': 'provider'
          }
      } } );
  },
  methods: {
    /**
     * @returns array
     */
     parseLocationToKeyValueArray() {
        return window.location.search.split('&').map(i => i.split('=').map(j => decodeURIComponent(j.replace('?',''))));
    },
    nextStep() {
      this.isLoading = true;
      if(this.provider.length === 0 || this.number === 0) {
        this.providerError = true;
        this.isLoading = false;
      } else {
        Backend.trackBeeswax(6)
        Storage.set('app.third_step',{
          insurance_provider: this.provider,
          yearly_salary: this.number
        });
        EventBus.$emit('app.progressBar.update',60);
        this.$router.push('/confirm-information');
      }
    },
    cantFind() {
      this.setEntry('Other');
      this.nextStep();
    },
    setEntry(e) {
      this.provider = e;
      this.isFound = true;
    }
  }
}
</script>

<style src="vue-advanced-search/dist/AdvancedSearch.css"></style>

<style scoped>

</style>
