import Vue from "vue";
import VueRouter from "vue-router";
import VueMask from "v-mask";
import App from "./App.vue";
import routes from "./routes";

import "./assets/styles/app.css";
import "./assets/tailwind.css";
import "./assets/styles/rating.scss";
import VueSvgGauge from "vue-svg-gauge";

Vue.config.productionTip = false;

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

Vue.use(VueRouter);
Vue.use(VueMask);
Vue.use(VueSvgGauge);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");