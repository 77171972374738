import { render, staticRenderFns } from "./Yeti.vue?vue&type=template&id=4b033746&scoped=true&"
import script from "./Yeti.vue?vue&type=script&lang=js&"
export * from "./Yeti.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b033746",
  null
  
)

export default component.exports