<template>
  <div class="text-center card">
    <h1 class="text-lg font-extrabold text-center text-black-300 md:font-sans">
      Thank you for verifying your info!
    </h1>

    <h2 class="text-sm font-semibold text-center text-black-300 md:font-sans">
      To complete your entry, follow the steps below.
    </h2>

    <img
      src="../../assets/party.png"
      class="object-center h-48 mx-auto mt-4"
    >

    <ol class="mt-4">
      <li>1. Click SUBSCRIBE to get notified if you WIN</li>
      <li>2. Complete the survey on the following page</li>
    </ol>

    <button
      :disabled="isLoading"
      class="inline-flex justify-center w-full px-4 py-2 mt-5 text-sm font-bold text-white bg-blue-500 border border-blue-500 rounded-md shadow-sm hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
      @click="subscribe"
    >
      <svg
        v-if="isLoading"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="inline-block my-1 animate-spin"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
        />
        <path
          d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
        />
      </svg>

      <span v-else>Subscribe</span>
    </button>

    <a
      v-show="showSkip"
      class="block mt-4 text-sm text-blue-500"
      href="/midpath"
    >
      Skip
    </a>
  </div>
</template>

<script>
import Backend from "@/backend";
import Storage from "@/storage";

const { detect } = require("detect-browser");
const browser = detect();

export default {
  data() {
    return {
      isLoading: false,
      showSkip: false,
    };
  },
  computed: {
    firstName() {
      return Storage.get("user.first_name");
    },
    lastName() {
      return Storage.get("user.last_name");
    },
  },
  mounted() {
    if (this.$route.params.member) {
      Backend.setMember(this.$route.params.member);
    }

    if (browser.name !== "chrome" && browser.name !== "android") {
      if (window.gtag) {
        window.gtag("event", "notification_unavailable", {
          event_category: "funnel",
        });
      }

      this.$router.push("/midpath");
    } else {
      if (window.gtag) {
        window.gtag("event", "notification_viewed", {
          event_category: "funnel",
        });
      }
    }
  },
  methods: {
    subscribe: function () {
      this.isLoading = true;

      window.Pushnami.update({
        fname: this.firstName,
        lname: this.lastName,
      }).prompt();

      if (window.gtag) {
        window.gtag("event", "notification_requested", {
          event_category: "funnel",
        });
      }

      let time = 0;

      const interval = setInterval(() => {
        time += 250;

        if (time > 3000) {
          clearInterval(interval);

          this.isLoading = false;
          this.showSkip = true;
        }

        if (Notification.permission === "granted") {
          if (window.gtag) {
            window.gtag("event", "notification_granted", {
              event_category: "funnel",
            });
          }

          clearInterval(interval);
          this.$router.push("/midpath");
        } else {
          console.log("not granted");
        }
      }, 250);
    },
  },
};
</script>
