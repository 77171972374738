<template>
  <div class="card">
    <solar-popup v-if="submitted" />

    <p>
      <strong>Please confirm your entry details:</strong>
    </p>
    <div class="p-2 my-5 bg-gray-50 text-gray-600">
      <div class="table">
        <div class="table-row text-xs">
          <div class="table-cell">
            <p class="font-semibold p-2">Phone Number</p>
          </div>
          <div class="table-cell">
            <p class="p-2">
              {{ user_phone }}
            </p>
          </div>
        </div>
        <div class="table-row text-xs">
          <div class="table-cell">
            <p class="font-semibold p-2">Email Address</p>
          </div>
          <div class="table-cell">
            <p class="p-2">
              {{ user_email }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <button
      class="
        inline-flex
        justify-center
        w-full
        rounded-md
        border border-blue-500
        shadow-sm
        px-4
        py-2
        bg-blue-500
        text-sm
        font-bold
        text-white
        hover:bg-gray-50
        focus:outline-none
        w-full
        ml-0
        mb-2
      "
      @click="nextStep"
    >
      <svg
        v-if="isLoading"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="inline-block animate-spin"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
        />
        <path
          d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
        />
      </svg>
      {{ newScreen === "giveaway" ? "Submit Health Survey" : "Confirm Entry" }}
    </button>

    <label class="mt-1 text-gray-400" style="font-size: 0.65rem"
      ><input id="leadid_tcpa_disclosure" type="hidden" /> By submitting this
      form, I expressly consent to be contacted by a
      <a
        href="https://www.acquirecrowd.com/partners/"
        target="_blank"
        style="text-decoration: underline"
        >partner</a
      >
      in the AcquireCrowd Network with materials for services via direct or
      electronic mail, phone calls to the phone number provided, text/SMS
      messages, via automatic/automated dialing system(s), and pre-recorded
      messages. Consent is not a condition of purchase and may be revoked at any
      time.
    </label>
  </div>
</template>
<script>
import { EventBus } from "@/eventbus.js";
import Storage from "@/storage.js";
import SolarPopup from "./SolarPopup.vue";
export default {
  components: { SolarPopup },
  data: () => ({
    offer_id_from_top: 0,
    submitted: false,
    user_phone: "",
    user_email: "",
  }),
  mounted() {
    EventBus.$emit("app.screenChanged", "submission");
    EventBus.$emit("app.stepChanged", 5);
    EventBus.$emit("app.progressBar.update", 100);

    this.user_phone = Storage.get("user.phone_number");
    this.user_email = Storage.get("user.email_address");
    this.offer_id_from_top = Storage.get("everflow.offer.id");
  },
  methods: {
    nextStep() {
      this.submitted = true;
      window.EF.conversion({
        offer_id: this.offer_id_from_top,
        email: this.user_email,
      });
    },
  },
};
</script>