<template>
  <div class="card">
    <div class="text-left">
      <p class="mt-5 mb-4 text-xs font-bold text-gray-500">
        {{ dobCTA }}
      </p>

      <form @submit.prevent="nextStep">
        <p class="mt-5 font-semibold text-black-500">
          Date Of Birth <small>*</small>
        </p>

        <input
          v-model="dobText"
          v-mask="'##/##/####'"
          type="text"
          :class="dobError ? 'reg-input border-red-500' : 'reg-input'"
          placeholder="mm/dd/yyyy"
        >
        <p
          v-if="dobError"
          class="mt-1 text-xs text-red-500"
        >
          Please enter a valid DOB.
        </p>

        <button
          :disabled="!validationIsTrue"
          class="inline-flex justify-center w-full px-4 py-2 mt-5 text-sm font-bold text-white bg-blue-500 border border-blue-500 rounded-md shadow-sm hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50"
          type="submit"
        >
          <svg
            v-if="isLoading"
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="inline-block my-1 animate-spin"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
            />
            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
          </svg>

          <span v-else>Confirm Age</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/eventbus";
import Backend from "@/backend";
import Storage from "@/storage";
import axios from "axios";

export default {
  name: "DateOfBirth",

  data() {
    return {
      isLoading: false,
      dobCTA: '',
      dobText: '',
      dobError: false,
      overtwenty: true
    };
  },
  computed: {
    validationIsTrue() {
      return this.dobText.length === 10;
    },
  },

  mounted() {
    let fbDOB = Storage.get('user.fb.birthday');
    if(typeof fbDOB == 'string') {
      this.dobText = fbDOB;
      this.nextStep();
    }
    
    window.dotq = window.dotq || [];
    window.dotq.push(
    {
      'projectId': '10000',
      'properties': {
        'pixelId': '10156295',
        'qstrings': {
          'et': 'custom',
          'ec': 'confirmage'
        }
    } } );

    // set the dob information panel
    this.$nextTick(() => {
      if(window.__screen === 'giveaway') {
        this.dobCTA = '🙋‍♀️🙋‍♂️ We collect date of birth to validate our winners!';
      } else {
        this.dobCTA = '🙋‍♀️🙋‍♂️ We collect date of birth to validate that you are a real person!';
      }
    });

    // get the query Params from earlier
    let queryParameters = Storage.get('app.queryParameters');

    // facebook conversion
    if(Object.prototype.hasOwnProperty.call(queryParameters, 'fbclid')) {
      axios.post('https://essentialhealth.app/api/convert-for-facebook',{
        fbclid: queryParameters.fbclid,
        event_name: 'Postal Code + Name Submission',
        event_url: this.$route.fullPath
      });
    }

    EventBus.$emit('app.stepChanged',2);
  },
  methods:{
    nextStep() {
      this.isLoading = true;

      if(this.dobText.length !== 10) {
        this.isLoading = false;
        this.dobError = true;
      } else {
        // split it up
        let dob = this.dobText.split('/');
        if(parseInt(dob[0]) <= 12 && parseInt(dob[1]) <= 31 && (parseInt(dob[2]) >= 1940 && parseInt(dob[2]) <= 2002)) {
          Storage.set('app.second_step',{
            dob_month: dob[0],
            dob_day: dob[1],
            dob_year: dob[2]
          });
          Storage.set('app.third_step',{
            income: this.overtwenty
          });
          setTimeout(() => {
            Backend.trackBeeswax(5)
            EventBus.$emit('app.progressBar.update',60);
            
            if (window.gtag) {
              window.gtag('event', 'confirm_age', {
                'event_category': 'funnel',
              });
            }
            
            this.$router.push('/confirm-information');
          }, 150);
        } else {
          this.isLoading = false;
          this.dobError = true;
        }
      }
    }
  }
}
</script>