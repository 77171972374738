<template>
  <div class="card">
    <div class="text-left">
      <!--    <p class="text-black-500 font-semibold">Postal Code*</p>-->
      <!--    <input pattern="[0-9]*" type="text" v-model="postalCode" v-mask="'#####'" :class="postalCodeError ? 'reg-input border-red-500' : 'reg-input'" placeholder="92656"/>-->
      <!--    <p v-if="postalCodeError" class="mt-1 text-xs text-red-500">Invalid zip code!</p>-->
      <p class="mt-4 text-black-500 text-lg md:font-sans text-center font-bold">
        52% of Americans overpay for their Health Insurance.
      </p>
      <div class="p-5">
        <img
          src="../../assets/pexels-gabby-k-5849554.jpg"
          class="m-auto block h-56 rounded-lg"
        >
      </div>
      <p class="mt-4 text-gray-500 text-lg md:font-sans text-center font-semibold">
        Take a short survey and see how much you can be saving every month
      </p>
      <p class="mt-4 text-black-400 md:font-sans">
        First Name*
      </p>
      <input
        v-model="firstName"
        name="first_name"
        class="mt-2 p-2.5 border border-gray-300 rounded-lg w-full block focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent font-sans"
        placeholder="Mia"
      >

      <p class="mt-4 text-black-400 md:font-sans">
        Last Name*
      </p>
      <input
        v-model="lastName"
        name="last_name"
        class="mt-2 p-2.5 border border-gray-300 rounded-lg w-full block focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent font-sans"
        placeholder="Smith"
      >
    </div>

    <div class="mt-5 text-sm mb-5">
      <p class="font-bold">
        We offer benefits from:
      </p>
      <div class="block">
        <div class="inline-block">
          <ul>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeOne"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>Aetna
            </li>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeTwo"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>Kaiser Permanente
            </li>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeThree"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>Oscar
            </li>
          </ul>
        </div>
        <div class="inline-block">
          <ul>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeOne"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>United Healthcare
            </li>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeTwo"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>Cigna Health
            </li>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeThree"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>Humana
            </li>
          </ul>
        </div>
      </div>
    </div>

    <button
      :disabled="!validationIsTrue"
      class="submit-button"
      @click="nextStep"
    >
      <svg
        v-if="isLoading"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="inline-block animate-spin"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
        />
        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
      </svg>&nbsp;Find Smarter Health Plans
    </button>
  </div>
</template>

<script>
import {EventBus} from "@/eventbus";
import Storage from "@/storage";
import axios from 'axios';
export default {
  name: "PostCodeAndName",
  data() {
    return {
      isLoading: false,
      postalCode: "",
      postalCodeError: false,
      firstName: "",
      lastName: "",
      colorChangeOne: '#000',
      colorChangeTwo: '#000',
      colorChangeThree: '#000',
    };
  },
  watch: {
    postalCode(oldVal, newVal) {
      if(newVal !== '') {
        this.colorChangeOne = '#3b82f6';
      }
    },
    firstName(oldVal, newVal) {
      if(newVal !== '') {
        this.colorChangeTwo = '#3b82f6';
      }
    },
    lastName(oldVal, newVal) {
      if(newVal !== '') {
        this.colorChangeThree = '#3b82f6';
      }
    },
  },
  mounted() {
    EventBus.$on('app.postalCode.loaded',d => {
      this.postalCode = d;
    });
    this.$nextTick(() => {
      Storage.set('channel','high');
      EventBus.$emit('app.screenChanged','calculator');
    });
  },
  methods: {
    loginWithFacebook() {
      window.FB.login((res) => {
        console.log(res);
      },{scope: 'public_profile,email,birthdate'});
    },
    validationIsTrue() {
      return this.postalCode !== '' && this.firstName !== '' && this.lastName !== '';
    },
    nextStep() {
      this.isLoading = true;
      axios.get('https://essentialhealth.app/api/lookup-zipcode?postalCode=' + this.postalCode).then(d => {
        if(Object.prototype.hasOwnProperty.call(d.data["ZipCode"],'Error')) {
          this.postalCodeError = true;
          this.isLoading = false;
        } else {
          Storage.set('app.first_step',{
            postal_code: this.postalCode,
            city: d.data["ZipCode"]["City"],
            state: d.data["ZipCode"]["State"],
            first_name: this.firstName,
            last_name: this.lastName
          });
          Storage.set('user.city', d.data['ZipCode']['City']);
          Storage.set('user.zip_code', this.postalCode);
          EventBus.$emit('app.progressBar.update',40);
          this.$router.push('/confirm-age');
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
