<template>
  <div class="text-center card">
    <h1 class="text-lg font-extrabold text-center text-black-300 md:font-sans">
      Congratulations you're almost there!
    </h1>

    <p class="mt-4 text-center">
      We're going to send you a link to confirm you're not a robot! Please check your <strong>email</strong> or <strong>texts</strong> to confirm!
    </p>

    <img
      src="../../assets/ai.png"
      class="object-center h-64 mx-auto mt-4"
    >
  </div>
</template>

<script>
export default {
  mounted() {
    if (window.gtag) {
      window.gtag('event', 'verify_phone', {
        'event_category': 'funnel',
      });
    }
  }
}
</script>