<template>
  <div class="text-center card">
    <iframe
      :src="`https://rexadz.com/Path10/?wsid=ekUxRjBkL014YjBiLzZkZk5QWUdOUT09&bgcolor=ffffff&pcolor=ffffff&ycolor=default&ytext=Yes&ncolor=default&ntext=No&maxiq=15&tcolor=black&fredirect=parent&${prepop}`"
      width="100%"
      frameborder="0"
      scrolling="no"
      onload="scroll(0,0);"
    />

    <div id="resizer" />
  </div>
</template>

<script>
import Storage from '@/storage'

export default {
  computed: {
    member () {
      return Storage.get('member')
    },
    prepop () {
      return [
        'fname=' + this.member.first_name,
        'lname=' + this.member.last_name,
        'email=' + this.member.email_address,
        'phone=' + this.member.phone_number,
        'zip=' + this.member.zip_code,
        'address=' + this.member.address,
        'gender=' + this.member.gender,
        'dob=' + this.member.dob_month + '/' + this.member.dob_day + '/' + this.member.dob_year,
        'redirect=' + window.location.origin + '/midpath-next',
      ].join('&')
    }
  },
  mounted () {
    if (window.gtag) {
      window.gtag('event', 'present_midpath', {
        'event_category': 'funnel',
      });
    }

    const script = document.createElement('script')
    script.setAttribute('src', 'https://rexadz.com/assets/js/iframeResizer.js')
    document.getElementById('resizer').appendChild(script)
  },
}
</script>
