<template>
  <div class="card">
    <div class="text-center mt-5">
      <p class="text-xl text-black-500 font-bold">
        Enter to win a Purple Pillow
      </p>
      <div class="p-5">
        <img
          src="../../assets/purple-pillows.png"
          class="rounded m-auto block h-56"
        >
      </div>
      <p class="mt-5 text-gray-500">
        Enter your information below and start our short health survey to enter
        and have a chance at winning.
      </p>
    </div>

    <form @submit.prevent="nextStep">
      <div class="text-left mt-5">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <p class="text-black-500 font-semibold">
              First Name <small>*</small>
            </p>

            <input
              v-model="firstName"
              class="
              mt-1
              p-2
              border border-gray-300
              rounded-lg
              w-full
              block
              shadow-md
              focus:outline-none
              focus:ring-2
              focus:ring-blue-500
              focus:border-transparent
            "
            >
          </div>

          <div>
            <p class="text-black-500 font-semibold">
              Last Name <small>*</small>
            </p>
            <input
              v-model="lastName"
              class="
              mt-1
              p-2
              border border-gray-300
              rounded-lg
              w-full
              block
              shadow-md
              focus:outline-none
              focus:ring-2
              focus:ring-blue-500
              focus:border-transparent
            "
            >
          </div>
        </div>

        <p class="mt-3 text-black-500 font-semibold">
          Postal Code <small>*</small>
        </p>

        <input
          v-model="postalCode"
          v-mask="'#####'"
          pattern="[0-9]*"
          type="text"
          :class="postalCodeError ? 'reg-input border-red-500' : 'reg-input'"
        >
        <p
          v-if="postalCodeError"
          class="mt-1 text-xs text-red-500"
        >
          Invalid zip code!
        </p>
      </div>

      <button
        :disabled="!validationIsTrue"
        class="
        mt-5
        inline-flex
        justify-center
        w-full
        rounded-md
        border border-blue-500
        shadow-sm
        px-4
        py-2
        bg-blue-500
        text-sm
        font-bold
        text-white
        hover:bg-blue-600
        disabled:cursor-not-allowed
        disabled:opacity-50
      "
        type="submit"
      >
        <svg
          v-if="isLoading"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          class="inline-block animate-spin my-1"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
          />
          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
        </svg>

        <span v-else>Next Step</span>
      </button>
    </form>

    <p class="text-gray-400 text-center text-xs mt-5">
      By clicking the button above you are agreeing to our
      <a
        class="underline font-bold text-blue-400"
        href="/privacy-policy"
      >
        privacy policy
      </a>
      and our
      <a
        class="underline font-bold text-blue-400"
        href="/sweepstakes-rules"
      >
        sweepstake rules
      </a>.
    </p>
  </div>
</template>

<script>
import { EventBus } from "@/eventbus";
import Storage from "@/storage";
import Backend from "@/backend";
import axios from "axios";
export default {
  name: "PurplePillow",
  data() {
    return {
      isLoading: false,
      postalCode: "",
      postalCodeError: false,
      firstName: "",
      lastName: "",
      colorChangeOne: "#000",
      colorChangeTwo: "#000",
      colorChangeThree: "#000",
    };
  },
  computed: {
    validationIsTrue() {
      return (
        this.postalCode.length === 5 &&
        this.firstName.length > 0 &&
        this.lastName.length > 0
      );
    },
  },
  watch: {
    postalCode(oldVal, newVal) {
      if (newVal !== "") {
        this.colorChangeOne = "#3b82f6";
      }
    },
    firstName(oldVal, newVal) {
      if (newVal !== "") {
        this.colorChangeTwo = "#3b82f6";
      }
    },
    lastName(oldVal, newVal) {
      if (newVal !== "") {
        this.colorChangeThree = "#3b82f6";
      }
    },
  },
  mounted() {
    EventBus.$on("app.postalCode.loaded", (d) => {
      this.postalCode = d;
    });
    this.$nextTick(() => {
      window.internal_offer_id = 3;
      EventBus.$emit("app.screenChanged", "giveaway");
      Backend.prePopRedirect(this.$router);
    });
  },
  methods: {
    nextStep() {
      this.isLoading = true;
      axios
        .get(
          "https://essentialhealth.app/api/lookup-zipcode?postalCode=" +
            this.postalCode
        )
        .then((d) => {
          if (
            Object.prototype.hasOwnProperty.call(d.data["ZipCode"], "Error")
          ) {
            this.postalCodeError = true;
            this.isLoading = false;
          } else {
            Storage.set("app.first_step", {
              postal_code: this.postalCode,
              city: d.data["ZipCode"]["City"],
              state: d.data["ZipCode"]["State"],
              first_name: this.firstName,
              last_name: this.lastName,
            });
            Backend.trackBeeswax(4)
            Storage.set("user.city", d.data["ZipCode"]["City"]);
            Storage.set("user.zip_code", this.postalCode);
            EventBus.$emit("app.progressBar.update", 50);
            this.$router.push("/confirm-age");
          }
        });
    },
  },
};
</script>

<style scoped>
</style>
