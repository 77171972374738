<template>
  <div class="card">
    <p class="text-center text-xl">
      <svg
        width="3em"
        height="3em"
        viewBox="0 0 16 16"
        class="inline-block animate-spin"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
        />
        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
      </svg>
    </p>
    <p class="text-gray-500 font-semibold text-xl mt-3 mb-2 text-center">
      Expect a call from us at the following number:
    </p>
    <p class="text-black-500 font-bold text-xl mt-3 mb-2 text-center">
      +1-650-239-3031
    </p>

    <div class="p-3 bg-gray-100">
      <ul class="text-xs text-white-500 text-center">
        <li>
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="inline-block"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
            />
          </svg>Saving money on your essential healthcare
        </li>
        <li>
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="inline-block"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
            />
          </svg>Qualify you for additional benefits
        </li>
        <li>
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            class="inline-block"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
            />
          </svg>Keep your policy information updated
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
name: "ThankYouForSigningUp"
}
</script>

<style scoped>

</style>