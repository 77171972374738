export const sweeps = {
  'blackstone': {
    name: 'blackstone',
    path: '/blackstone',
    image: 'blackstone.jpg',
    class: 'h-64 my-5'
  },
  'lazy': {
    name: 'lazy',
    path: '/lazy',
    image: 'lazy.jpg',
    class: 'h-64 my-5'
  },
  'purple-pillow': {
    name: 'Purple Pillow',
    path: '/purple-pillow',
    image: 'purplePillow.jpg',
    class: 'h-64 my-5'
  },
  'oura-ring': {
    name: 'Oura Ring',
    path: '/oura-ring',
    image: 'ouraRing.jpg',
    class: 'h-64 my-5'
  },
  'e-bike': {
    name: 'E-Bike',
    path: '/e-bike',
    image: 'ebike.jpg',
    class: 'h-52 my-5'
  },
  'supe73-bike': {
    name: 'Super 73 Bike',
    path: '/supe73-bike',
    image: 'Supe73.png',
    class: 'h-64 my-5'
  },
  'yamaha-4-string-bass-guitar': {
    name: 'yamaha 4 string bass guitar',
    path: '/yamaha-4-string-bass-guitar',
    image: 'yamaha-4-string-bass-guitar.png',
    class: 'h-64 my-5'
  },
  'bluetooth-stereo-turntable': {
    name: 'Bluetooth Stereo Turntable',
    path: '/bluetooth-stereo-turntable',
    image: 'bluetooth-stereo-turntable.png',
    class: 'h-64 my-5'
  },
  '5g-wifi-projector': {
    name: '5g Wifi Projector',
    path: '/5g-wifi-projector',
    image: '5g-wifi-projector.png',
    class: 'h-64 my-5'
  },
  'flair-espresso': {
    name: 'Flair Espresso',
    path: '/flair-espresso',
    image: 'flair-espresso.png',
    class: 'h-64 my-5'
  },
  'titan-telescoping-flagpole': {
    name: 'Titan Telescoping Flagpole',
    path: '/titan-telescoping-flagpole',
    image: 'titan-telescoping-flagpole.png',
    class: 'h-64 my-5'
  },
  'yeti-trailhead-two-in-one-dog-bed': {
    name: 'Yeti Trailhead Two In One Dog Bed',
    path: '/yeti-trailhead-two-in-one-dog-bed',
    image: 'yeti-trailhead-two-in-one-dog-bed.png',
    class: 'h-64 my-5'
  },
  'high-chair': {
    name: 'Micuna® OVO Max Luxe Convertible High Chair',
    path: '/high-chair',
    image: 'high-chair.png',
    class: 'h-64 my-5'
  },
  'wave-crib': {
    name: 'The Wave Crib',
    path: '/wave-crib',
    image: 'wave-crib.png',
    class: 'h-64 my-5'
  },
  'complete-stroller': {
    name: 'The Complete Stroller',
    path: '/complete-stroller',
    image: 'complete-stroller.png',
    class: 'h-64 my-5'
  }

}