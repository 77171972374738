<template>
  <div class="card">
    <div class="text-center py-5">
      <p class="text-xl font-semibold">
        Can I get better insurance?
      </p>
      <p class="text-gray-500">
        You're on the right path.<br>Learn more below.
      </p>
    </div>

    <div class="text-left">
      <!--    <p class="text-black-500 font-semibold">Postal Code*</p>-->
      <!--    <input pattern="[0-9]*" type="text" v-model="postalCode" v-mask="'#####'" :class="postalCodeError ? 'reg-input border-red-500' : 'reg-input'" placeholder="92656"/>-->
      <!--    <p v-if="postalCodeError" class="mt-1 text-xs text-red-500">Invalid zip code!</p>-->

      <button
        :class="`submit-button ${isLoading ? 'bg-blue-300' : 'bg-blue-500'}`"
        :disabled="isLoading"
        @click="loginWithFacebook"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="table-cell align-middle mr-1"
          viewBox="0 0 16 16"
        >
          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
        </svg><span class="table-cell align-middle">Continue With Facebook</span>
      </button>

      <button
        class="mt-2 submit-button bg-white border-gray-500 text-gray-500"
        :disabled="isLoading"
        @click="manualEntry = true"
      >
        I want to fill things out manually
      </button>

      <div v-if="manualEntry">
        <p class="mt-5 text-black-500 font-semibold">
          First Name*
        </p>
        <input
          v-model="firstName"
          name="first_name"
          class="mt-1 p-2 border border-gray-300 rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="Mia"
        >

        <p class="mt-3 text-black-500 font-semibold">
          Last Name*
        </p>
        <input
          v-model="lastName"
          name="last_name"
          class="mt-1 p-2 border border-gray-300 rounded-lg w-full block shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="Smith"
        >
      </div>
    </div>

    <div class="mt-5 text-sm mb-5">
      <p class="font-bold">
        We offer benefits from:
      </p>
      <div class="block">
        <div class="inline-block">
          <ul>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeOne"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>Aetna
            </li>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeTwo"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>Kaiser Permanente
            </li>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeThree"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>Oscar
            </li>
          </ul>
        </div>
        <div class="inline-block">
          <ul>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeOne"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>United Healthcare
            </li>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeTwo"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>Cigna Health
            </li>
            <li>
              <svg
                width="2em"
                height="2em"
                viewBox="0 0 16 16"
                class="inline-block"
                :fill="colorChangeThree"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                />
              </svg>Humana
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="manualEntry">
      <button
        :disabled="!validationIsTrue"
        class="submit-button"
        @click="nextStep"
      >
        <svg
          v-if="isLoading"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          class="inline-block animate-spin"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
          />
          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
        </svg>&nbsp;Find Smarter Health Plans
      </button>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/eventbus";
import Storage from "@/storage";
import axios from 'axios';
export default {
  name: "SocialPage",
  data() {
    return {
      isLoading: false,
      manualEntry: false,
      postalCode: "",
      postalCodeError: false,
      firstName: "",
      lastName: "",
      colorChangeOne: '#000',
      colorChangeTwo: '#000',
      colorChangeThree: '#000',
    };
  },
  watch: {
    postalCode(oldVal, newVal) {
      if(newVal !== '') {
        this.colorChangeOne = '#3b82f6';
      }
    },
    firstName(oldVal, newVal) {
      if(newVal !== '') {
        this.colorChangeTwo = '#3b82f6';
      }
    },
    lastName(oldVal, newVal) {
      if(newVal !== '') {
        this.colorChangeThree = '#3b82f6';
      }
    },
  },
  mounted() {
    EventBus.$on('app.postalCode.loaded',d => {
      this.postalCode = d;
    });
    this.$nextTick(() => {
      Storage.set('channel','high');
    });
  },
  methods: {
    loginWithFacebook() {
      this.isLoading = true;
      window.FB.login((res) => {
        let userId = res.authResponse.userID;
        window.FB.api(`/${userId}?fields=name,email,birthday`,(user) => {

          if(Object.prototype.hasOwnProperty.call(user, 'name')) {
            this.firstName = user.name.split(" ")[0];
            this.lastName = user.name.split(" ")[1];
          }

          if(Object.prototype.hasOwnProperty.call(user, 'birthday')) {
            // set the birthday
            Storage.set('user.fb.birthday',user.birthday);
          }

          if(Object.prototype.hasOwnProperty.call(user,'email')) {
            Storage.set('user.fb.email',user.email);
          }

          // after storage has been set, go ahead and push them through
          axios.get('https://essentialhealth.app/api/lookup-zipcode?postalCode=' + this.postalCode).then(d => {
            console.log(d.data);
            if(Object.prototype.hasOwnProperty.call(d.data["ZipCode"],'Error')) {
              this.postalCodeError = true;
              this.isLoading = false;
            } else {
              Storage.set('app.first_step',{
                postal_code: this.postalCode,
                city: d.data["ZipCode"]["City"],
                state: d.data["ZipCode"]["State"],
                first_name: this.firstName,
                last_name: this.lastName
              });
              Storage.set('user.city', d.data['ZipCode']['City']);
          Storage.set('user.zip_code', this.postalCode);
              EventBus.$emit('app.progressBar.update',50);
              this.$router.push('/confirm-age');
            }
          });

        });
      },{scope: 'public_profile,email,user_birthday'});
    },
    validationIsTrue() {
      return this.postalCode !== '' && this.firstName !== '' && this.lastName !== '';
    },
    nextStep() {
      this.isLoading = true;
      axios.get('https://essentialhealth.app/api/lookup-zipcode?postalCode=' + this.postalCode).then(d => {
        if(Object.prototype.hasOwnProperty.call(d.data["ZipCode"],'Error')) {
          this.postalCodeError = true;
          this.isLoading = false;
        } else {
          Storage.set('app.first_step',{
            postal_code: this.postalCode,
            city: d.data["ZipCode"]["City"],
            state: d.data["ZipCode"]["State"],
            first_name: this.firstName,
            last_name: this.lastName
          });
          Storage.set('user.city', d.data['ZipCode']['City']);
          Storage.set('user.zip_code', this.postalCode);
          EventBus.$emit('app.progressBar.update',50);
          this.$router.push('/confirm-age');
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
