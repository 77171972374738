import {EventBus} from "@/eventbus";
import Storage from "@/storage";
import axios from 'axios';

const Backend = {

    /**
     * @returns array
     */
     parseLocationToKeyValueArray() {
        return window.location.search.split('&').map(i => i.split('=').map(j => decodeURIComponent(j.replace('?',''))));
    },

    submitted: false,
    prePopRedirect(router) {
        let keys = [
            // 'dob_month',
            // 'dob_day',
            // 'dob_year',
            'utm_source',
            'utm_content',
            'utm_channel',
            // 'utm_keyword',
            // 'first_name',
            // 'last_name',
            'email',
            'phone',
            // 'address',
            'zip'
        ];
        let checks  = 0;

        // validate
        let data    = this.parseLocationToKeyValueArray();

        data.map(i => checks = checks + (keys.includes(i[0]) && i[1] != ''));

        if(keys.length === checks) {
            
            let userData = {}; 
            data.map(i => userData[i[0]] = i[1]);

            console.log(userData);

            let x = setInterval(() => {
                if(window.trustedForm && window.trustedForm.id) {
                    clearInterval(x); 
                    setTimeout(() => {
                        this.prePopSignup(userData, router);
                    }, 25);
                }
            }, 50);

        }
    },

    /**
     * @param {*} address 
     * @param {*} zip_code 
     */
     zipCodeToCityState(address, zip_code) {
        return new Promise(res => {
            axios.post(`https://maps.googleapis.com/maps/api/geocode/json?address=${address} ${zip_code}&key=AIzaSyAaUaNlzqX0mcX-XfZfINC84TuSb_1-3nc`).then(d => {
                console.log(d.data);
                if(d.data.results.length > 0) {
                    let components = d.data.results[0].address_components;
                    console.log(components);
                    let city = components.filter(i => i.types.includes('locality'))[0].short_name;
                    let state = components.filter(i => i.types.includes('administrative_area_level_1'))[0].short_name;
                    res({
                        'city': city,
                        'state': state
                    });
                } else {
                    res({
                        'city': 'Austin',
                        'state': 'TX'
                    });
                }
            });
        });
    },

    prePopSignup(data, router) {

        console.log(router);

        if(!this.submitted) {
            this.submitted = true;
            let bucket = {};

            // trusted form...
            if(Object.prototype.hasOwnProperty.call(window,'trustedForm')) {
                bucket['trusted_form'] = 'https://cert.trustedform.com/' + window.trustedForm.id;
            }

            // jornaya...
            if(Object.prototype.hasOwnProperty.call(window,'LeadiD')) {
                bucket['jornaya'] = window.LeadiD.token;
            }

            let affiliateTracking = Storage.get('affiliateTracking');
            
            if(
                Object.prototype.hasOwnProperty.call(affiliateTracking,'lr_campaign_id') && 
                Object.prototype.hasOwnProperty.call(affiliateTracking,'lr_click_id')) {
                    bucket['utm_source'] = 'affiliate';
            }

            this.zipCodeToCityState(data['address'], data['zip_code']).then(d => {
                bucket['yearly_salary'] = 25000;
                bucket['address'] = data['address'];
                Storage.set('user.address', bucket['address']);
                bucket['address_two'] = '';
                bucket['city'] = d.city;
                Storage.set('user.city', bucket['city']);
                bucket['state'] = d.state;
                Storage.set('user.state', bucket['state']);
                bucket['current_bill'] = '';
                bucket['dob_day'] = data['dob_day'];
                bucket['dob_month'] = data['dob_month'];
                bucket['dob_year'] = data['dob_year'];
                Storage.set('user.dob',{
                    dob_month: bucket['dob_month'],
                    dob_day: bucket['dob_day'],
                    dob_year: bucket['dob_year']
                });
                bucket['email_address'] = data['email'];
                bucket['fbclid'] = '';
                bucket['phone_number'] = data['phone'];
                bucket['first_name'] = data['first_name'];
                Storage.set('user.first_name', bucket['first_name']);
                bucket['last_name'] = data['last_name'];
                Storage.set('user.last_name', bucket['last_name']);
                bucket['insurance_provider'] = '';
                bucket['postal_code'] = data['zip'];
                bucket['zip_code'] = data['zip'];
                Storage.set('zip_code', bucket['zip_code']);
                bucket['ip_address'] = Storage.get('app.clientIpAddress');
                bucket['address_two'] = "";
                bucket['timestamp'] = Date.now();
    
                Storage.set('user.phone_number', bucket['phone_number']);
                Storage.set('user.email_address', bucket['email_address']);

                let tracking = Storage.get('affiliateTracking');
                if(Object.prototype.hasOwnProperty.call(tracking, 'lr_campaign_id')) {
                    axios.post(`https://api.leadreserve.com/v1/lead/convert/${tracking.lr_campaign_id}/${tracking.lr_click_id}`);
                }

                axios.post('https://essentialhealth.app/api/new-member', bucket).then(() => {
                    router.push('/pp-submission');
                });
            });

        }
    },

    /**
     * Current user
     * @returns {{last_name: string, first_name: string, token: string}}
     */
    getCurrentUser() {
        // todo: send back the name, phone, and token for lead gen offers
        return {
            token: Storage.get('app.token'),
            first_name: Storage.get('user.first_name'),
            last_name: Storage.get('user.last_name'),
            email_address: Storage.get('user.email_address'),
            phone_number: Storage.get('user.phone_number')
        };
    },

    /**
     * Take the local storage steps and turn that into a new user;
     * @returns {{}}
     */
    parseStorageIntoUserObject() {
        // create the data bucket
        let bucket = {};

        // go through the storage
        const storageKeys = ['app.first_step','app.second_step','app.third_step','app.fourth_step','app.rating_step'];

        // merge-central
        storageKeys.map(i => {
           bucket = {...bucket,...Storage.get(i)};
        });

        // trusted form...
        if(Object.prototype.hasOwnProperty.call(window,'trustedForm')) {
            bucket['trusted_form'] = 'https://cert.trustedform.com/' + window.trustedForm.id;
        }

        // jornaya...
        if(Object.prototype.hasOwnProperty.call(window,'LeadiD')) {
            bucket['jornaya'] = window.LeadiD.token;
        }

        bucket['zip_code'] = bucket['postal_code'];
        bucket['ip_address'] = Storage.get('app.clientIpAddress');
        bucket['address_two'] = "";
        bucket['timestamp'] = Date.now();

        return bucket;
    },

    registerUser() {
        return new Promise(resolve => {
            let payload = this.parseStorageIntoUserObject();

            payload.utm_source = Storage.get('utm_source', null)
            payload.utm_medium = Storage.get('utm_medium', null)
            payload.utm_campaign = Storage.get('utm_campaign', null)
            payload.utm_term = Storage.get('utm_term', null)
            payload.utm_content = Storage.get('utm_content', null)

            axios.post('https://essentialhealth.app/api/new-member', payload).then(d => {
                Storage.set('user.token', d.data.token);
                Storage.set('user.first_name', payload.first_name);
                Storage.set('user.last_name', payload.last_name);
                Storage.set('user.phone_number', payload.phone_number);
                Storage.set('user.email_address', payload.email_address);

                Storage.set('member', d.data.member);

                EventBus.$emit('app.registrationCompleted', payload);

                resolve(d.data);
            })
        });
    },

    validatePhone(phone) {
        return axios.get(`https://essentialhealth.app/api/validate-phone/${phone}`)
    },

    setMember(id) {
        axios.get(`https://essentialhealth.app/api/members/${id}`).then(response => {
            Storage.set('member', response.data)
        })
    },

    trackBeeswax: function (tagId) {
        const buster = Math.floor(Math.random() * 10000000000)
        const img = new Image()
  
        img.width = 0
        img.height = 0
        
        img.src = `https://cnv.event.prod.bidr.io/log/cnv?tag_id=${tagId}&buzz_key=acquirecrowd&value=0&account_id=2&ord=${buster}`
  
        document.body.appendChild(img)
      }
};

export default Backend;
