<template>
  <div class="card">
    <div class="text-left">
      <!-- Always separate elements on page by white space... So other developers can help :) -->
      <p class="mt-4 text-center text-black-300 md:font-sans font-semi">
        <span class="font-extrabold">We'll call you soon!</span><br>
        We're excited. You could save as much as:
      </p>

      <div class="mt-5">
        <!-- Added 'inner-radius' to make it thinner... -->
        <VueSvgGauge
          :start-angle="-90"
          :end-angle="90"
          :value="8"
          :separator-step="0"
          :min="0"
          :max="10"
          :inner-radius="90"
          :gauge-color="[{ offset: 0, color: '#ff0202'}, {offset: 50, color: '#fff800'}, { offset: 100, color: '#2bff05'}]"
          :scale-interval="0.1"
        >
          <div class="absolute bottom-0 w-full mx-auto text-center text-gray-500">
            <span class="text-xl font-extrabold text-green-500">+${{ savingsYear }}</span><br>Every single year.
          </div>
        </VueSvgGauge>
      </div>

      <!-- Give the user immediate call to action -- WAITING ON THIS UNTIL WE GET DID FROM BUYERS -->
      <!--
      <p class="mt-5 mb-5 text-center">Wow! You should call in now! 👇</p>
      <a href="tel:+18559730857" class="justify-center block w-full px-4 py-2 mt-2 text-sm font-medium text-center text-gray-700 bg-red-500 border-0 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none">
        <svg width="1em" height="1em" viewBox="0 0 16 16" class="inline-block" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
        </svg> <span class="text-white"><span class="font-extrabold">Call Now</span>: Your Agent Is Waiting</span>
      </a>
      -->

      <!-- adding the finger because its funny! haha. -->
      <div class="relative">
        <p
          class="absolute block text-4xl"
          style="transform: rotateZ(-45deg);top:0rem;left:-1.25rem;"
        >
          👇
        </p>
        <a
          href="https://mysolarbook.com?utm_source=EssentialHealthInfo&utm_keywords=HealthCalculator&utm_channel=Facebook"
          target="_blank"
          class="mt-5 bg-cover bg-contain border-0 submit-button bg-gradient-to-r from-green-400 via-teal-500 to-blue-500"
        >
          🎉<span class="pl-1">Want to add $15K to your home value?</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/eventbus";
import Storage from "@/storage";
export default {
  name: "BadRating",
  data() {
    return {
      isLoading: false,
      savings: 0,
      savingsYear: 0,

      home_insurance:true,
      home_improvemnet: true
    };
  },
  mounted() {
    EventBus.$emit('app.stepChanged', 5);

    // actually generate savings this time - wrap in a "toFixed" method
    this.savings = (this.getRandomInt(150, 350) * (0.67)).toFixed(2);

    // calculate the savings year.. Normally we don't want to recalculate anything ;)
    this.savingsYear = (this.savings * 12).toFixed(2);
  },
  methods: {
        /**
     * Build out a random method to generate an int from min-max
     * @param min
     * @param max
     * @returns {number}
     */
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
    nextStep() {
      this.isLoading = true;
      Storage.set('app.rating_step',{

      });

      setTimeout(() => {
        EventBus.$emit('app.progressBar.update',100);
        this.$router.push('/confirm-interest');
      },150);
    },
    validationIsTrue() {
      return true;
    }
  }
}
</script>

<style scoped>

</style>
