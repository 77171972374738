<template>
  <div class="card">
    <div class="my-2 mt-5">
      <img src="../../assets/brandedsurveys_logo.png" class="w-32 mb-4" />
      <p class="mb-1 text-black-500 font-semibold">
        Join Branded Surveys for free! Make Money with Paid Surveys
      </p>
      <p class="mb-2 text-xs text-black-300">
        Check your email to receive your $1 Welcome Bonus
      </p>
      <div class="block mb-2">
        <button
          :class="
            'ml-0 ' + (survey_interest ? 'reg-button-selected' : 'reg-button')
          "
          @click="survey_interest = true"
        >
          Yes
        </button>
        <button
          :class="!survey_interest ? 'reg-button-selected' : 'reg-button'"
          @click="survey_interest = false"
        >
          No
        </button>
      </div>
      <p class="text-xs text-gray-500">
        <a
          class="text-blue-500"
          href="https://surveys.gobranded.com/page/branded-surveys-privacy-policy/"
          target="_blank"
          >Privacy Policy</a
        >
      </p>
    </div>

    <div class="my-2 mt-5">
      <img src="../../assets/ziprecruiter_logo.png" class="w-40 mb-4" />
      <p class="mb-1 text-black-500 font-semibold">
        Sign up to receive job alert emails from ZipRecruiter
      </p>
      <p class="mb-2 text-xs text-black-300">
        Get the latest jobs to your inbox daily. We do the searching for you!
      </p>
      <div class="block mb-2">
        <button
          :class="
            'ml-0 ' + (job_interest ? 'reg-button-selected' : 'reg-button')
          "
          @click="job_interest = true"
        >
          Yes
        </button>
        <button
          :class="!job_interest ? 'reg-button-selected' : 'reg-button'"
          @click="job_interest = false"
        >
          No
        </button>
      </div>
      <p class="text-xs text-gray-500">
        By checking the box, I agree to the ZipRecruiter Terms of Use and
        acknowledge I have read the Privacy Policy, and agree to receive daily
        email job alerts.
      </p>
    </div>

    <button
      :disabled="!validationIsTrue"
      class="mt-5 submit-button"
      @click="nextStep"
    >
      <svg
        v-if="isLoading"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="inline-block animate-spin"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
        />
        <path
          d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
        /></svg
      >&nbsp;Confirm Questions
    </button>
  </div>
</template>

<script>
export default {
  name: "CoRegPage",
  data: () => ({
    job_interest: true,
    survey_interest: true,
    validationIsTrue: 1,
    isLoading: false,
  }),
  methods: {
    nextStep() {
      this.$router.push("/offers");
    },
  },
};
</script>

<style scoped>
</style>
