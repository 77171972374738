<template>
  <div class="card">
    <div class="text-left">
      <p class="mt-4 text-black-400 md:font-sans">
        First Name*
      </p>
      <input
        v-model="firstName"
        name="first_name"
        class="mt-2 p-2.5 border border-gray-300 rounded-lg w-full block focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent font-sans"
        placeholder="Mia"
      >

      <p class="mt-4 text-black-400 md:font-sans">
        Last Name*
      </p>
      <input
        v-model="lastName"
        name="last_name"
        class="mt-2 p-2.5 border border-gray-300 rounded-lg w-full block focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent font-sans"
        placeholder="Smith"
      >
    </div>

    <button
      :disabled="!validationIsTrue"
      class="
      mt-5
        inline-flex
        justify-center
        w-full
        rounded-md
        border border-blue-500
        shadow-sm
        px-4
        py-2
        bg-blue-500
        text-sm
        font-bold
        text-white
        hover:bg-blue-600
        disabled:cursor-not-allowed
        disabled:opacity-50
      "
      @click="nextStep"
    >
      <svg
        v-if="isLoading"
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="inline-block animate-spin my-1"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
        />
        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
      </svg>

      <span v-else>Next Step</span>
    </button>
  </div>
</template>

<script>
import {EventBus} from "@/eventbus";
import Storage from "@/storage";
import axios from 'axios';
export default {
  name: "PostCodeAndName",
  data() {
    return {
      isLoading: false,
      postalCode: "",
      postalCodeError: false,
      firstName: "",
      lastName: "",
      colorChangeOne: '#000',
      colorChangeTwo: '#000',
      colorChangeThree: '#000',
    };
  },
  watch: {
    postalCode(oldVal, newVal) {
      if(newVal !== '') {
        this.colorChangeOne = '#3b82f6';
      }
    },
    firstName(oldVal, newVal) {
      if(newVal !== '') {
        this.colorChangeTwo = '#3b82f6';
      }
    },
    lastName(oldVal, newVal) {
      if(newVal !== '') {
        this.colorChangeThree = '#3b82f6';
      }
    },
  },
  mounted() {
    EventBus.$on('app.postalCode.loaded',d => {
      this.postalCode = d;
    });
    this.$nextTick(() => {
      Storage.set('channel','high');
    });
    window.dotq = window.dotq || [];
    window.dotq.push(
    {
      'projectId': '10000',
      'properties': {
        'pixelId': '10156295',
        'qstrings': {
          'et': 'custom',
          'ec': 'name'
        }
    } } ); 
  },
  methods: {
    loginWithFacebook() {
      window.FB.login((res) => {
        console.log(res);
      },{scope: 'public_profile,email,birthdate'});
    },
    validationIsTrue() {
      return this.postalCode !== '' && this.firstName !== '' && this.lastName !== '';
    },
    nextStep() {
      this.isLoading = true;
      axios.get('https://essentialhealth.app/api/lookup-zipcode?postalCode=' + this.postalCode).then(d => {
        if(Object.prototype.hasOwnProperty.call(d.data["ZipCode"],'Error')) {
          this.postalCodeError = true;
          this.isLoading = false;
        } else {
          Storage.set('user.first_name', this.firstName);
          Storage.set('app.first_step',{
            postal_code: this.postalCode,
            city: d.data["ZipCode"]["City"],
            state: d.data["ZipCode"]["State"],
            first_name: this.firstName,
            last_name: this.lastName
          });
          Storage.set('user.city', d.data['ZipCode']['City']);
          Storage.set('user.zip_code', this.postalCode);
          EventBus.$emit('app.progressBar.update',40);
          this.$router.push('/confirm-age');
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
